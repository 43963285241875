import { ReactElement } from 'react';
import { useDispatch, useSelector } from '../store';
import { GlobalModals } from '../store/initial/modal';
import { hideModal } from '../store/reducers/modalReducer';
import Button, { ButtonTheme } from './Button';
import Icon, { Icons } from './Icon';
import Modal from './Modal';

export type GlobalContextualModalProps = {

  title?: string;
  imageAboveHeaderSource?: string;
  description?: string;
  confirmLabel: string;
  dismissLabel?: string;
  onConfirm?: ()=> void;
  onDismiss?: ()=> void;
  confirmButtonTheme?: ButtonTheme;
  titleClassName?: string;
  contentClassName?: string;
  iconAboveHeader?: Icons;
  confirmButtonId?:string;
}

const GlobalContextualModal = ({
  title,
  imageAboveHeaderSource,
  description,
  confirmLabel,
  dismissLabel,
  onConfirm,
  onDismiss,
  confirmButtonTheme = 'primary',
  titleClassName = '',
  contentClassName = '',
  iconAboveHeader,
}: GlobalContextualModalProps ): ReactElement => {
  const dispatch = useDispatch();
  const isOpen = useSelector( state => state.modal.data.contextualModal.isOpen );

  const handleConfirm = () => {
    dispatch( hideModal( GlobalModals.contextualModal ));
    onConfirm && onConfirm();
  };

  const handleDismiss = () => {
    dispatch( hideModal( GlobalModals.contextualModal ));
    onDismiss && onDismiss();
  };

  return (
    <Modal isOpen={ isOpen } type="contextual" contentClassName={ `text-center ${contentClassName}` }>
      {( imageAboveHeaderSource || iconAboveHeader ) && <div className="w-full flex justify-center mb-5">
        {imageAboveHeaderSource && <img src={ imageAboveHeaderSource } alt="contextual graphic" className="w-1/2" />}
        {iconAboveHeader && <Icon name={ iconAboveHeader } className="text-salamander w-10 h-12" />}
      </div> }
      {title && <h1 id='modal-title' className={ `text-black font-bold mb-4 ${titleClassName}` }>{title}</h1> }
      {description && <p className="mb-4">{description}</p>}
      <Button
        onClick={ handleConfirm }
        theme={ confirmButtonTheme }
        className="mt-4 self-center"
        id='modal-button'
      >
        {confirmLabel}
      </Button>
      {dismissLabel && <Button onClick={ handleDismiss } theme="clean-secondary" className="mt-4 self-center"> {dismissLabel}</Button>}
    </Modal>
  );
};

export default GlobalContextualModal;
