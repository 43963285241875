import { UserEntitlements } from '../UserInterface';

//@TODO: Hardcoded from CDS Guest User defaults - remove when we can fetch from CDS without Ethos JWT
export const loggedOutDefaultEntitlements: UserEntitlements = {
  documents: {
    will: {
      feature: 'disabled',
      upgradeable: 'enabled',
      isSupported: true,
      pourover: 'disabled',
      upgrades: [
        {
          id: 'a1884aa6-1161-4ab7-8170-5d034140ec48',
          type: 'SUBSCRIPTION',
          name: 'Premium Trust',
          price: 19999,
          subscription_length: '+1 year +1 day',
          skus: [
            {
              id: '77af3eff-ceb1-4215-b434-87d391e637dd',
              sku: 'com.tomorrow.premium.trust',
              platform: 'apple',
            },
          ],
        },
        {
          id: '167525ce-3399-4235-accc-54f84135e8f5',
          type: 'PRODUCT',
          name: 'PremiumWill - $49',
          price: 4900,
          subscription_length: '+1 year +1 day',
          skus: [
            {
              id: '1bbedaab-be6c-4673-a582-4b748e4c9d06',
              sku: 'ethos.simple.will',
              platform: 'stripe',
            },
          ],
        },
        {
          id: '8d6ac9e8-5aa3-4110-9839-256748e5116c',
          type: 'PRODUCT',
          name: 'Estate Plan',
          price: 19900,
          subscription_length: '+1 year +1 day',
          skus: [
            {
              id: '091e5de1-1599-4fca-ae04-abb619e562b6',
              sku: 'ethos.estate.plan',
              platform: 'stripe',
            },
          ],
        },
      ],
      restrictions: [],
    },
    trust: {
      feature: 'disabled',
      upgradeable: 'enabled',
      isSupported: true,
      joint: 'disabled',
      upgrades: [
        {
          id: 'a1884aa6-1161-4ab7-8170-5d034140ec48',
          type: 'SUBSCRIPTION',
          name: 'Premium Trust',
          price: 19999,
          subscription_length: '+1 year +1 day',
          skus: [
            {
              id: '77af3eff-ceb1-4215-b434-87d391e637dd',
              sku: 'com.tomorrow.premium.trust',
              platform: 'apple',
            },
          ],
        },
        {
          id: '8d6ac9e8-5aa3-4110-9839-256748e5116c',
          type: 'PRODUCT',
          name: 'Estate Plan',
          price: 19900,
          subscription_length: '+1 year +1 day',
          skus: [
            {
              id: '091e5de1-1599-4fca-ae04-abb619e562b6',
              sku: 'ethos.estate.plan',
              platform: 'stripe',
            },
          ],
        },
      ],
      restrictions: [],
    },
    medical_consent: {
      feature: 'disabled',
      upgradeable: 'enabled',
      isSupported: true,
      upgrades: [
        {
          id: 'a1884aa6-1161-4ab7-8170-5d034140ec48',
          type: 'SUBSCRIPTION',
          name: 'Premium Trust',
          price: 19999,
          subscription_length: '+1 year +1 day',
          skus: [
            {
              id: '77af3eff-ceb1-4215-b434-87d391e637dd',
              sku: 'com.tomorrow.premium.trust',
              platform: 'apple',
            },
          ],
        },
        {
          id: '8d6ac9e8-5aa3-4110-9839-256748e5116c',
          type: 'PRODUCT',
          name: 'Estate Plan',
          price: 19900,
          subscription_length: '+1 year +1 day',
          skus: [
            {
              id: '091e5de1-1599-4fca-ae04-abb619e562b6',
              sku: 'ethos.estate.plan',
              platform: 'stripe',
            },
          ],
        },
      ],
      restrictions: [],
    },
    healthcare_directive: {
      feature: 'disabled',
      upgradeable: 'enabled',
      isSupported: true,
      upgrades: [
        {
          id: 'a1884aa6-1161-4ab7-8170-5d034140ec48',
          type: 'SUBSCRIPTION',
          name: 'Premium Trust',
          price: 19999,
          subscription_length: '+1 year +1 day',
          skus: [
            {
              id: '77af3eff-ceb1-4215-b434-87d391e637dd',
              sku: 'com.tomorrow.premium.trust',
              platform: 'apple',
            },
          ],
        },
        {
          id: '8d6ac9e8-5aa3-4110-9839-256748e5116c',
          type: 'PRODUCT',
          name: 'Estate Plan',
          price: 19900,
          subscription_length: '+1 year +1 day',
          skus: [
            {
              id: '091e5de1-1599-4fca-ae04-abb619e562b6',
              sku: 'ethos.estate.plan',
              platform: 'stripe',
            },
          ],
        },
      ],
      restrictions: [],
    },
    power_of_attorney: {
      feature: 'disabled',
      upgradeable: 'enabled',
      isSupported: true,
      upgrades: [
        {
          id: 'a1884aa6-1161-4ab7-8170-5d034140ec48',
          type: 'SUBSCRIPTION',
          name: 'Premium Trust',
          price: 19999,
          subscription_length: '+1 year +1 day',
          skus: [
            {
              id: '77af3eff-ceb1-4215-b434-87d391e637dd',
              sku: 'com.tomorrow.premium.trust',
              platform: 'apple',
            },
          ],
        },
        {
          id: '8d6ac9e8-5aa3-4110-9839-256748e5116c',
          type: 'PRODUCT',
          name: 'Estate Plan',
          price: 19900,
          subscription_length: '+1 year +1 day',
          skus: [
            {
              id: '091e5de1-1599-4fca-ae04-abb619e562b6',
              sku: 'ethos.estate.plan',
              platform: 'stripe',
            },
          ],
        },
      ],
      'restrictions': [],
    },
  },
  insurance: {
    life: {
      feature: 'enabled',
      provider: 'Ethos',
      subtype: 'term',
      restrictions: [],
    },
    policy: {
      feature: 'disabled',
      provider: 'Ethos',
      subtype: 'term',
      restrictions: [],
    },
    disability: {
      feature: 'unavailable',
      upgrades: [],
      restrictions: [] },
  },
};
