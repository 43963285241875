import { FC } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';

// Components
import Icon from '../../components/Icon';
import Translation from '../../components/Translation';

// Routes
import { home, login, profile } from '../../../core/routes';
import { useSelector } from '../../store';
import { ethosPartnerCodeSelector } from '../../store/selectors/userSelector';

export interface HeaderProps {
  isLoggedIn: boolean;
}

const Header: FC<HeaderProps> = ({ isLoggedIn }) => {
  const location = useLocation();
  const shouldRenderSignInLink = !useSelector( ethosPartnerCodeSelector );

  return (
    <header className="mt-4">
      <div className="container">
        <nav>
          <ul className="flex py-2 border-b-1 items-center">
            <Link to={ home.path }>
              <Icon
                name="logoMark"
                className="text-black h-6 w-auto"
              />
            </Link>

            {<li className="flex-1 flex justify-end">
              {isLoggedIn ? (
                <NavLink
                  to={{
                    pathname: profile.get(),
                    state: { background: location },
                  }}
                  className="p-2 -mr-2"
                >
                  <div className="w-6 h-6 md:h-8 md:w-8">
                    <Icon name="cog" className="w-full h-full text-black-50" />
                  </div>
                </NavLink>
              ) : shouldRenderSignInLink && (
                <NavLink
                  to={{
                    pathname: login.get(),
                    state: { background: location },
                  }}
                  className="font-bold text-forest hover:text-forest--hover"
                  id={'signin-link'}
                >
                  <Translation translationKey={ 'screen.home.button.signin' } />
                </NavLink>
              )}
            </li>}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
