import { AxiosResponse } from 'axios';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

// Actions
import { hasAuthenticated, hasCompletedOnboarding } from './authReducer';
import { IPatchPerson, patchPerson, updatePerson } from './peopleReducer';

// Utils
import WebClientRequest from '../../core-data-service/WebClientRequest';
import { stringToBoolean } from '../../../core/utils/booleanUtils';

import initialState from '../initial/user';

import UserInterface, { UserAttributes, UserEntitlements } from '../../types/UserInterface';
import { RootState } from '..';
import { socket, WebsocketEvent } from '../../providers/WebsocketProvider';
import analytics from '../../utils/analytics';
import { formatDate } from '../../../core/utils/datetimeUtils';

const CORE_DATA_SERVICE_BOOLEAN = 'BOOLEAN';
const CORE_DATA_SERVICE_INTEGER = 'INTEGER';
const CORE_DATA_SERVICE_STRING = 'STRING';

export const isValidStringUserAttribute = ( key: string ): boolean => {
  // branch link params that should be passed to the backend begin with `ua_`
  return key.startsWith( 'ua_' ) || [ 'app.trustSignedTrustDate','app.trustSignedPourOverWillDate' ].includes( key );
};

interface IFetchUser {
  onSuccess?: ( response: AxiosResponse )=> void;
  onError?: ( error: string )=> void;
}

interface IPatchUser {
  onSuccess?: ( response: AxiosResponse )=> void;
  onError?: ( error: string )=> void;
  email?: string | null;
  phone?: string | null;
  referral_id?: string | null;
}

interface IDeleteUser {
  onSuccess?: ( response: AxiosResponse )=> void;
}

interface ISetPartnerCode {
  partnerCode: string;
}

interface ISetUserAttributes extends Partial<UserAttributes>{
  onSuccess?: ( response: AxiosResponse )=> void;
}

export const fetchUser = createAsyncThunk<
  WebClientRequest,
  IFetchUser | void,
  {state: RootState}
>(
  'user/fetchUser',
  async( arg: IFetchUser | void, thunkAPI ) => {
    const url = '/v1/users/me';
    return WebClientRequest
      .get( url )
      .then( initialUserResponse => {

        // If we get to this point we know we are successfully communicating with the CDS
        // we have a valid JWT and we can set authenticated to TRUE
        thunkAPI.dispatch( hasAuthenticated( true ));

        const initialUser = initialUserResponse.data.data;
        const initialPerson = initialUserResponse.data.data.person;

        // Check to confirm if our initial user is onboarded
        let isUserOnboarded = (
          !!initialPerson.name &&
          !!initialPerson.address.zip &&
          !!initialPerson.dob &&
          !!initialUser.email );
        thunkAPI.dispatch( hasCompletedOnboarding( isUserOnboarded ));

        analytics.identify( initialUser.id );

        // If isUserOnboarded is FALSE - attempt to update with onboarding data
        if ( !isUserOnboarded ) {

          const personID = initialUser.person.id;
          const onboardingIdentity = thunkAPI.getState().onboarding.data;

          // Transform onboarding identity to personData
          const personData: IPatchPerson = {
            name: onboardingIdentity.nameFirst+ ' ' +onboardingIdentity.nameLast,
            email: onboardingIdentity.email,
            dob: formatDate( onboardingIdentity.birthDate ),

            // Guarding against overwriting an existing zipcode with an empty one (some ethos accounts may be missing addresses)
            address:  !!onboardingIdentity.addressData?.postalCode ? { zip: onboardingIdentity.addressData?.postalCode } : initialPerson.address,
            gender: onboardingIdentity.gender.toUpperCase(),
          };

          thunkAPI.dispatch( patchPerson({ id: personID, ...personData,

            // Patch person success
            onSuccess: _patchPersonResponse => {

              thunkAPI.dispatch( patchUser({ email: onboardingIdentity.email,

                // Patch user success
                onSuccess: patchedUserResponse => {

                  // Only set hasCompletedOnboarding if we also have all the necessary data
                  const patchedPerson = patchedUserResponse.data.data.person;
                  const patchUser = patchedUserResponse.data.data;
                  isUserOnboarded = (
                    !!patchedPerson.name &&
                    !!patchedPerson.address.zip &&
                    !!patchedPerson.dob &&
                    !!patchUser.email );

                  thunkAPI.dispatch( hasCompletedOnboarding( isUserOnboarded ));

                  arg && arg.onSuccess && arg.onSuccess( patchedUserResponse );
                },

                // Patch user error
                onError: ( error: string ) => {
                  arg && arg.onError && arg.onError( error );
                },
              }));
            },

            // Patch person error
            onError: ( error: string ) => {
              arg && arg.onError && arg.onError( error );
            } }));
        } else {

          // No need to run side effects - complete the request
          thunkAPI.dispatch( updateUser( initialUser ));
          thunkAPI.dispatch( updatePerson( initialPerson ));
          arg && arg.onSuccess && arg.onSuccess( initialUserResponse );
        }
      })
      .catch( error => {
        arg && arg.onError && arg.onError( error );
      });
  },
);


export const patchUser = createAsyncThunk(
  'user/patchUser',
  async({ onSuccess, onError, ...data }: IPatchUser, thunkAPI ) => {
    const url = '/v1/users/me';
    return WebClientRequest
      .patch( url, data )
      .then( response => {
        const user = response.data.data;
        const person = response.data.data.person;
        thunkAPI.dispatch( updateUser( user ));
        thunkAPI.dispatch( updatePerson( person ));
        onSuccess && onSuccess( response );
      })
      .catch( error => {
        onError && onError( error );
      });
  },
);

export const deleteUser = createAsyncThunk(
  'user/deleteUser',
  async( arg: IDeleteUser | void, thunkAPI ) => {
    const url = '/v1/users/me';
    return WebClientRequest
      .delete( url )
      .then( response => {
        arg && arg.onSuccess && arg.onSuccess( response );
      });
  },
);


export const setUserPartnerCode = createAsyncThunk(
  'user/setPartnerCode',
  async({ partnerCode }: ISetPartnerCode, thunkAPI ) => {
    const url = '/v1/user_partners';
    const data = {
      'partner_code': partnerCode,
    };
    /**
     * this can return a 409 if user _already_ has a conflicting partner code
     * this is a no-op
     */
    return WebClientRequest
      .post( url, data );
  },
);


export const setUserAttributes = createAsyncThunk(
  'user/setUserAttributes',
  async({ onSuccess, ...data }: ISetUserAttributes, thunkAPI ) => {
    const url = '/v2/attributes';

    const transformedData = Object.entries( data ).map( entry => {
      const [ key, value ] = entry;
      const dataObject: {
        key: string;
        value: boolean | number | string | null;
        type: typeof CORE_DATA_SERVICE_BOOLEAN | typeof CORE_DATA_SERVICE_INTEGER | typeof CORE_DATA_SERVICE_STRING |null;
      } = {
        key,
        value: null,
        type: null,
      };
      switch( typeof value ) {
      case 'string':
        /**
         * Generally speaking, strings passed here should be parsed as booleans
         * unless the key is in the string "allow list" returned by the function above
         */
        if ( isValidStringUserAttribute( key )) {
          dataObject['value'] = value;
          dataObject['type'] = CORE_DATA_SERVICE_STRING;
        } else {
          dataObject['value'] = stringToBoolean( value );
          dataObject['type'] = CORE_DATA_SERVICE_BOOLEAN;
        }
        break;
      case 'boolean':
        dataObject['value'] = value;
        dataObject['type'] = CORE_DATA_SERVICE_BOOLEAN;
        break;
      case 'number':
        dataObject['value'] = value;
        dataObject['type'] = CORE_DATA_SERVICE_INTEGER;
        break;
      default:
        break;
      }
      return dataObject;
    });
    return WebClientRequest
      .post( url, transformedData )
      .then( response => {
        thunkAPI.dispatch( saveAttributes( response.data.data ));
        onSuccess && onSuccess( response );
      });
  },
);


const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {

    updateUser( user, action ){
      // Extract `person` from payload, and set `user.person_id`
      // Note: We don't want any `person` object on the user

      const { person, ...payload } = action.payload;
      payload.person_id = person.id;

      const normalizedPayload = payload as UserInterface;
      // Mutate `user` with values from the rest of the payload
      user.data = { ...user.data, ...normalizedPayload };

      // let the websocket service know who we are
      socket.emit( WebsocketEvent.identifyUser, payload.id );
      // @TODO: Should update `person` in person store?
    },

    updateUserEntitlements( user, action: PayloadAction<UserEntitlements> ){
      user.data.entitlements = action.payload;
    },

    saveAttributes( user, action: PayloadAction<UserAttributes> ){
      user.data.attributes = action.payload;
    },

    // use sparingly, this will not unset values or set anything to "null"
    saveAttribute( user, action: PayloadAction<UserAttributes> ){
      user.data.attributes = { ...user.data.attributes, ...action.payload };
    },
  },


  extraReducers: builder => {

    // fetchUser status
    builder.addCase( fetchUser.pending, ( user, action ) => {
      const { arg, ...meta } = action.meta;
      user.fetchUser.meta = meta;
    });
    builder.addCase( fetchUser.fulfilled, ( user, action ) => {
      const { arg, ...meta } = action.meta;
      user.fetchUser.meta = meta;
    });
    builder.addCase( fetchUser.rejected, ( user, action ) => {
      const { arg, ...meta } = action.meta;
      user.fetchUser.meta = meta;
    });

    // patchUser status
    builder.addCase( patchUser.pending, ( user, action ) => {
      const { arg, ...meta } = action.meta;
      user.patchUser.meta = meta;
    });
    builder.addCase( patchUser.fulfilled, ( user, action ) => {
      const { arg, ...meta } = action.meta;
      user.patchUser.meta = meta;
    });
    builder.addCase( patchUser.rejected, ( user, action ) => {
      const { arg, ...meta } = action.meta;
      user.patchUser.meta = meta;
    });

    // deleteUser status
    builder.addCase( deleteUser.pending, ( user, action ) => {
      const { arg, ...meta } = action.meta;
      user.deleteUser.meta = meta;
    });
    builder.addCase( deleteUser.fulfilled, ( user, action ) => {
      const { arg, ...meta } = action.meta;
      user.deleteUser.meta = meta;
    });
    builder.addCase( deleteUser.rejected, ( user, action ) => {
      const { arg, ...meta } = action.meta;
      user.deleteUser.meta = meta;
    });

    // setUserPartnerCode status
    builder.addCase( setUserPartnerCode.pending, ( user, action ) => {
      const { arg, ...meta } = action.meta;
      user.setUserPartnerCode.meta = meta;
    });
    builder.addCase( setUserPartnerCode.fulfilled, ( user, action ) => {
      const { arg, ...meta } = action.meta;
      user.setUserPartnerCode.meta = meta;
    });
    builder.addCase( setUserPartnerCode.rejected, ( user, action ) => {
      const { arg, ...meta } = action.meta;
      user.setUserPartnerCode.meta = meta;
    });

    // setUserAttributes status
    builder.addCase( setUserAttributes.pending, ( user, action ) => {
      const { arg, ...meta } = action.meta;
      user.setUserAttributes.meta = meta;
    });
    builder.addCase( setUserAttributes.fulfilled, ( user, action ) => {
      const { arg, ...meta } = action.meta;
      user.setUserAttributes.meta = meta;
    });
    builder.addCase( setUserAttributes.rejected, ( user, action ) => {
      const { arg, ...meta } = action.meta;
      user.setUserAttributes.meta = meta;
    });

  },
});

export const { updateUser, saveAttributes, saveAttribute, updateUserEntitlements } = userSlice.actions;

export default userSlice.reducer;
