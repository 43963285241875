import { RootState } from '..';
import { ETHOS_POLICY_HOLDER_PARTNER_CODE } from '../../../core/constants';
import UserInterface from '../../types/UserInterface';


export const userSelector = ( state: RootState ): UserInterface => {
  return state.user.data;
};

export const ethosPartnerCodeSelector = ( state: RootState ): boolean => {
  return state.user.data?.partners?.length > 0 && state.user.data?.partners[0].code === ETHOS_POLICY_HOLDER_PARTNER_CODE;
};


export default userSelector;
