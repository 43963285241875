import branch from 'branch-sdk';
import { useEffect } from 'react';
// Redux
import { useDispatch, useSelector } from '../store';
import userSelector from '../store/selectors/userSelector';

// Reducers
import { fetchConfig } from '../store/reducers/configReducer';
import { fetchGoals } from '../store/reducers/goalsReducer';
import { fetchInheritances } from '../store/reducers/inheritancesReducer';
import { setIsLoading } from '../store/reducers/isLoadingReducer';
import { fetchPeople } from '../store/reducers/peopleReducer';
import { fetchProperties } from '../store/reducers/propertiesReducer';
import { fetchRoles } from '../store/reducers/rolesReducer';
import { fetchTranslations } from '../store/reducers/translationsReducer';
import { fetchUser, setUserAttributes, setUserPartnerCode } from '../store/reducers/userReducer';
import { fetchDocuments } from '../store/reducers/documentsReducer';
import { fetchExperiments } from '../store/reducers/experimentsReducer';
import { fetchApplications } from '../store/reducers/applicationsReducer';
import { fetchInvitations } from '../store/reducers/invitationsReducer';
import { fetchPowers } from '../store/reducers/powersReducer';

// Utils
import appConfig from '../../core/appConfig';
import { filterAttributionParams } from '../utils/analytics';
import { LOCAL_STORAGE_REFERRAL_ID, LOCAL_STORAGE_UTM_ATTRIBUTION, QUERY_REFERRAL_ID, SEARCH_PARAM_PARTNER_CODE } from '../../core/constants';

// Types
import { BranchData } from '../types/BranchData';
import { fetchDirectives } from '../store/reducers/directivesReducer';
import { login } from '../store/reducers/onboardingReducer';
import { makeSnack } from '../store/reducers/snackbarReducer';
import useAuth from './useAuth';


interface IUseFetchData {
  hasCompleted: boolean;
  isLoading: boolean;
  tokenGrant?: string;
}

const useFetchData = (): IUseFetchData => {
  const dispatch = useDispatch();
  const { hasCompletedOnboarding } = useAuth();
  const accessToken = useSelector( state => state.auth.access_token );

  const isLoading = useSelector( state => state.isLoading );

  const hasFetchedConfig = useSelector( state => state.config.fetchConfig.meta.requestStatus !== 'pending' );
  const hasFetchedUser = useSelector( state => state.user.fetchUser.meta.requestStatus !== 'pending' );
  const hasFetchedPeople = useSelector( state => state.people.fetchPeople.meta.requestStatus !== 'pending' );
  const hasFetchedProperties = useSelector( state => state.properties.fetchProperties.meta.requestStatus!== 'pending' );
  const hasFetchedInheritances = useSelector( state => state.inheritances.fetchInheritances.meta.requestStatus !== 'pending' );
  const hasFetchedRoles = useSelector( state => state.roles.fetchRoles.meta.requestStatus !== 'pending' );
  const hasFetchedGoals = useSelector( state => state.goals.fetchGoals.meta.requestStatus !== 'pending' );
  const hasFetchedDocuments = useSelector( state => state.documents.fetchDocuments.meta.requestStatus !== 'pending' );
  const hasFetchedExperiments = useSelector( state => state.experiments.fetchExperiments.meta.requestStatus !== 'pending' );
  const hasFetchedTranslations = useSelector( state => state.translations.fetchTranslations.meta.requestStatus !== 'pending' );
  const hasFetchedPowers = useSelector( state => state.powers.fetchPowers.meta.requestStatus !== 'pending' );
  const hasFetchedDirectives = useSelector( state => state.directives.fetchDirectives.meta.requestStatus !== 'pending' );

  const hasCompleted = Boolean(
    hasFetchedConfig &&
    hasFetchedDocuments &&
    hasFetchedGoals &&
    hasFetchedInheritances &&
    hasFetchedPeople &&
    hasFetchedProperties &&
    hasFetchedRoles &&
    hasFetchedExperiments &&
    hasFetchedUser &&
    hasFetchedTranslations &&
    hasFetchedPowers &&
    hasFetchedDirectives,
  );

  const user = useSelector( userSelector );

  /**
   * If a user logs in, all of the dispatches have already been run, so they
   * end up seeing a flash of content without this useState variable.
   */

  // set `isLoading` false after complete
  useEffect(() => {
    if( hasCompleted && isLoading ) {
      dispatch( setIsLoading( false ));
    }
  }, [ hasCompleted, dispatch, isLoading ]);

  // initial data fetch ....
  useEffect(() => {
    dispatch( fetchTranslations());
  }, [ dispatch ]);

  // fetch user data if token grant is password
  useEffect(() => {
    const runDispatches = () => {
      dispatch( fetchExperiments());

      dispatch( fetchUser());

      dispatch( fetchConfig());

      dispatch( fetchPeople());

      dispatch( fetchInvitations());

      dispatch( fetchProperties());

      dispatch( fetchInheritances());

      dispatch( fetchRoles());

      dispatch( fetchGoals());

      dispatch( fetchDocuments());

      dispatch( fetchApplications());

      dispatch( fetchPowers());

      dispatch( fetchDirectives());
      /**
       * If you grab the translations before you have a user access token,
       * there will be no test data in the response.
       */
      dispatch( fetchTranslations());
    };

    const query = new URLSearchParams( window.location.search );

    // encode/store UTM params for later use in insurance funnel
    window.localStorage.setItem( LOCAL_STORAGE_UTM_ATTRIBUTION, btoa( filterAttributionParams( window.location.search )));

    // set ethos referral_id for if/when acct is created
    const referral_id = query.get( QUERY_REFERRAL_ID );
    if ( referral_id ) {
      window.localStorage.setItem( LOCAL_STORAGE_REFERRAL_ID, referral_id );
    }

    // hard-coded partner code param required for epp
    // @TODO remove me when branch is integrated to ethos
    const partnerCode = query.get( SEARCH_PARAM_PARTNER_CODE );
    if ( partnerCode ) {
      dispatch( setUserPartnerCode({ partnerCode }));
    }

    // Branch experience directly affects experiments
    branch.init( appConfig.branch.key, {}, ( _, data: BranchData | null ) => {
      const filteredParsedData = Object.entries( data?.data_parsed || {}).reduce(( acc, curr ) => {
        const[ key, value ] = curr;
        if ( key.startsWith( 'ua_' )) {
          acc[key] = `${value}`;
        }
        return acc;
      }, {} as {[key: string]: string});

      // branch partner code param required advertisements
      const partnerCode = data?.data_parsed['partner_code'];
      const singleUseToken = data?.data_parsed['singleUseToken'];

      // If we have a singleUseToken and have NOT completed onboarding - login the user
      if ( singleUseToken &&  !hasCompletedOnboarding ) {
        dispatch( login({ singleUseToken, onSuccess: response=>{
          dispatch( makeSnack({ theme: 'success', message: 'You have been logged in.' }));
        } }));
      }

      if ( partnerCode ) {
        dispatch( setUserPartnerCode({ partnerCode }));
      }

      // Don't run dispatches without an access token
      if ( accessToken ) {
        if( Object.keys( filteredParsedData ).length ) {
          dispatch( setUserAttributes({ ...filteredParsedData, onSuccess: ()=> {
            runDispatches();
          } }));
        } else {
          runDispatches();
        }
      }
    });

  }, [ user.id ]);

  return {
    hasCompleted,
    isLoading,
  };
};


export default useFetchData;
