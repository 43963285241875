import React, { FC } from 'react';
import { FieldErrors, RegisterOptions,  UseFormMethods } from 'react-hook-form';


export interface CheckBoxProps{
  className?: string;
  title?: React.ReactNode | string;
  label: React.ReactNode | string;
  id?: string;
  name: string;
  value?: string;
  errors: FieldErrors;
  register: UseFormMethods['register'];
  required?: RegisterOptions['required'];
  color?: string;
  autoFocus?: boolean;
  disabled?: boolean;
}

export const Checkbox: FC<CheckBoxProps> = ({
  className='',
  title='',
  label,
  id,
  name,
  errors,
  value,
  register,
  required = false,
  autoFocus,
  disabled = false,
}) =>{
  const fieldError = errors[name];
  const registerOptions = {
    required,
  } as RegisterOptions;
  return(
    <div className={ `flex-col  ${className}` }>
      <div className={ 'checkbox flex mt-4' }>

        <label
          className="checkbox-container cursor-pointer flex items-center w-full"
          htmlFor={ name }
        >
          <input
            type="checkbox"
            name={ name }
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={ autoFocus }
            value={ value }
            id={ id }
            ref={ register( registerOptions ) }
            disabled={ disabled }
          />
          <div className={ 'checkmark border-2 mr-4' }/>
          <div>
            <div className={ 'font-bold' }>{title}</div>
            <div className={ 'text-base' }>{label}</div>
          </div>

        </label>
      </div>
      {
        !!fieldError &&
        <div role="alert" className="text-red-500 mt-2">
          { fieldError.message }
        </div>
      }
    </div>
  );
};
