import { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

// Types
import SanitizedFormData from '../../../core/types/SanitizedFormData';

// Utils
import { useDispatch, useSelector } from '../../store';
import analytics from '../../utils/analytics';
import { fieldRequired } from '../../formValidators';
import useTranslations from '../../hooks/useTranslations';

// Components
import Button from '../../components/Button';
import Form from '../../components/Form';
import TextField from '../../components/TextField';
import Translation from '../../components/Translation';

// Redux
import { saveOnboardingIdentity, sendLoginEmail } from '../../store/reducers/onboardingReducer';
import onboardingSelector from '../../store/selectors/onboardingSelector';
import { shouldUserVerifyEmail } from '../../store/reducers/authReducer';

interface PromptForEmailProps {
  className?: string;
  onLoginEmailSent?: ()=> void;
  onError?: ()=> void;
}

interface PromptForEmailFormData extends SanitizedFormData {
  email: string;
}

const PromptForEmail: FC<PromptForEmailProps> = ({
  className,
  onLoginEmailSent,
  onError,
}) => {

  // Application state
  const dispatch = useDispatch();
  const { getTranslation } = useTranslations();
  const onboardingIdentity = useSelector( onboardingSelector );

  // Form hooks
  const { register, setValue, getValues, handleSubmit, formState } = useForm<PromptForEmailFormData>({
    reValidateMode: 'onChange',
    defaultValues: {
      email: onboardingIdentity.email,
    },
  });

  const isPending = useSelector( state => state.onboarding?.sendLoginEmail?.meta?.requestStatus === 'pending' );
  const { errors } = formState;
  const useFormProps = { register, setValue, getValues, errors };

  const onSubmit: SubmitHandler<SanitizedFormData> = data => {
    dispatch( saveOnboardingIdentity({ ...onboardingIdentity, ...data }));

    const payload = {
      email: data.email ?? '',
      additionalParameters: {
        source:'eppweb',
      },
    };

    dispatch( sendLoginEmail({
      ...payload ,
      onSuccess: () => {
        // Save shouldVerifyEmail to true so Auth knows which prompt to render
        dispatch( shouldUserVerifyEmail( true ));
        onLoginEmailSent && onLoginEmailSent();
      },
      onError: () => {
        onError && onError();
      },
    }));
  };

  useEffect(() => {
    analytics.track( 'View - Registration Email' );
  }, []);

  return(
    <Form
      className={ className }
      handleSubmit={ handleSubmit }
      onSubmit={ onSubmit }
    >
      <h1 className="text-3xl mb-6 text-black font-headline"><Translation translationKey={ 'account.signup.email.title' }/></h1>
      <TextField { ...useFormProps }
        large
        className="mb-6"
        label={ <Translation translationKey={ 'screen.onboarding.email.address' } /> }
        name="email"
        required={ fieldRequired( getTranslation( 'error.invalid.email' )) }
        type="email"
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={ true }
      />
      <p className="mb-6 text-black-50">
        <Translation translationKey={ 'account.signup.email.desc' }/>
      </p>
      <div className="flex justify-end">
        <Button
          isPending={ isPending }
          id="onboarding-continue-button"
          type="submit"
        >Continue</Button>
      </div>
    </Form>
  );
};

export default PromptForEmail;
