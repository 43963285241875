import { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { Card, CardImage, CardProps } from '../../components/Card';

// Hooks
import useTranslations from '../../hooks/useTranslations';

// Redux
import { showContextualModal } from '../../store/reducers/modalReducer';
import userPersonSelector from '../../store/selectors/userPersonSelector';

// Routes
import { appStore as appStoreRoute } from '../../../core/routes';

// Types
import { DocumentEntitlements } from '../../types/Entitlements/EntitlementInterface';
import statesUtil from '../../utils/statesUtil';

// Utils
import { getEntitlementNameKey } from '../../utils/entitlementsUtils';

export interface DashboardDocumentCardProps extends CardProps {
    media: CardImage;
    isEnabled?: boolean;
    isSupported?: boolean;
    shouldShowProLabel?: boolean;
    documentName: keyof DocumentEntitlements;
    data_tid?: string;
}

export const DashboardDocumentCard = ({
  children,
  documentName,
  isEnabled = true,
  isSupported = true,
  data_tid,
  media,
  primaryAction = () => undefined,
  primaryActionLabel,
  shouldShowProLabel = false,
  title,
}: DashboardDocumentCardProps ): ReactElement => {

  const { push, location } = useHistory();
  const dispatch = useDispatch();
  const { getTranslation } = useTranslations();
  const userPerson = useSelector( userPersonSelector );
  const stateCode = userPerson?.address?.state;

  const notSupportedToolPrimaryAction = () => {
    push({ pathname: appStoreRoute.get(), state: { background: location } });
  };

  const handlePrimaryAction = () => {

    // @TODO: EPP Analytics
    if( !isSupported ) {
      // web app doesn't support this feature
      notSupportedToolPrimaryAction();
    } else if( !isEnabled ) {
      // zip code doesn't support this document
      dispatch( showContextualModal(
        {
          title: getTranslation( 'alert.unsupportedDocument.description', [ getTranslation( getEntitlementNameKey( documentName )), stateCode ? statesUtil.stateByCode( stateCode ) : 'your state' ]),
          confirmLabel: getTranslation( 'alert.unsupportedDocument.button' ),
          contentClassName: 'bg-moss',
        },
      ));
    }
    primaryAction();
  };

  return (
    <Card
      className={ `p-4 bg-white rounded-lg border-black-20 ${isEnabled && isSupported ? '' : 'opacity-50'}` }
      title={ title }
      primaryActionId={data_tid}
      primaryAction={ handlePrimaryAction }
      primaryActionLabel={ primaryActionLabel }
      childrenContainerClassName="max-w-[240px]"
      mediaClasses="w-28"
      mediaComponent={
        <div className="w-full relative">
          {( shouldShowProLabel ) &&
          <div className="bg-salamander w-[37px] h-[22px] items-center top-[-5px] left-[-5px] rounded-sm absolute z-10 flex justify-center content-center">
            <span style={{ letterSpacing: '.85px' }} className="text-xs text-white">PRO</span>
          </div>
          }
          <div className="flex-1 aspect-w-1 aspect-h-1">
            <img className="rounded-[12px]" src={ media.url } alt={ media.alt } />
          </div>
        </div>
      }
    >{ children }</Card>
  );
};
