import { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

// Types
import SanitizedFormData from '../../../core/types/SanitizedFormData';

// Components
import Button from '../../components/Button';
import Form from '../../components/Form';
import TextField from '../../components/TextField';
import Translation from '../../components/Translation';

// Hooks
import useTranslations from '../../hooks/useTranslations';

// Utils
import analytics from '../../utils/analytics';
import inputMask from '../../utils/inputMask';
import { fieldRequired, zipMinLength } from '../../formValidators';
import { useDispatch, useSelector } from '../../store';
import onboardingSelector from '../../store/selectors/onboardingSelector';
import OnboardingInterface from '../../types/OnboardingInterface';
import useAuth from '../../hooks/useAuth';
import { patchPerson } from '../../store/reducers/peopleReducer';
import userSelector from '../../store/selectors/userSelector';


interface PromptForZipProps {
  className?: string;
  onFormComplete?: ( data: Partial<OnboardingInterface> )=> void;
}

interface PromptForZipFormData extends SanitizedFormData {
  zip: string;
}

const PromptForZip: FC<PromptForZipProps> = ({
  className,
  onFormComplete,
}) => {

  const onboardingIdentity = useSelector( onboardingSelector );
  // Application state
  const { getTranslation } = useTranslations();
  const { hasAuthenticated } = useAuth();
  const dispatch = useDispatch();
  const user = useSelector( userSelector );

  // Form hooks
  const { register, getValues, setValue, handleSubmit, formState } = useForm<PromptForZipFormData>({
    reValidateMode: 'onChange',
    defaultValues: {
      zip: onboardingIdentity.addressData?.postalCode ?? '',
    },
  });

  useEffect(() => {
    analytics.track( 'View - Registration Zip' );
  }, []);

  const { errors } = formState;
  const useFormProps = { register, getValues, setValue, errors };

  // onSubmit handler
  const onSubmit: SubmitHandler<SanitizedFormData> = data => {
    const addressData = { ...onboardingIdentity.addressData, postalCode: data.zip ?? '' };

    // If we're already authenticated then patch the user
    if ( hasAuthenticated ) {
      const patchData = { address: { zip: data.zip ?? '' } };

      dispatch( patchPerson({ id: user.person_id, ...patchData,
        onSuccess: response=> {
          onFormComplete && onFormComplete({ addressData });
        },
      }));
    } else {
      onFormComplete && onFormComplete({ addressData });
    }
  };

  return(
    <Form
      className={ className }
      handleSubmit={ handleSubmit }
      onSubmit={ onSubmit }
    >
      <h1 className="text-3xl mb-6 text-black font-headline"><Translation translationKey={ 'account.signup.location.title' }/></h1>
      <TextField { ...useFormProps }
        large
        className="mb-6"
        label={ <Translation translationKey={ 'screen.onboarding.zipcode.field.placeholder' }/> }
        minLength={ zipMinLength( getTranslation( 'screen.onboarding.zipcode.error.invalid' )) }
        name="zip"
        normalize={ inputMask.zip }
        required={ fieldRequired( getTranslation( 'screen.onboarding.zipcode.error.invalid' )) }
        type="tel"
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={ true }
      />
      <p className="mb-6 text-black-50">
        <Translation translationKey={ 'account.signup.location.desc' } />
      </p>
      <div className="flex justify-end">
        <Button
          type="submit"
          id="onboarding-continue-button"
        ><Translation translationKey="(button)continue"/></Button>
      </div>
    </Form>
  );
};

export default PromptForZip;
