import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

// hooks
import useFlow from '../../hooks/useFlow';

// Components
import Flow from '../../components/Flow';
import PromptForBirthday from './PromptForBirthday';
import PromptForName from './PromptForName';
import PromptForZip from './PromptForZip';
import PromptForState from './PromptForState';
import PromptForGender from './PromptForGender';

// Redux
import { useDispatch, useSelector } from '../../store';
import userPersonSelector from '../../store/selectors/userPersonSelector';
import { createAccount, saveOnboardingIdentity, sendLoginEmail } from '../../store/reducers/onboardingReducer';
import OnboardingInterface from '../../types/OnboardingInterface';
import onboardingSelector from '../../store/selectors/onboardingSelector';
import Authenticate from '../Authenticate';
import { makeSnack } from '../../store/reducers/snackbarReducer';
import appConfig from '../../../core/appConfig';
import LoadingSpinner from '../../components/LoadingSpinner';
import useAuth from '../../hooks/useAuth';
import { fetchUser } from '../../store/reducers/userReducer';
import { shouldUserVerifyEmail } from '../../store/reducers/authReducer';

interface Props {
  onComplete: ()=> void;
}

type OnboardingQuestions =  Array<[React.ElementType, boolean]>

export default function OnboardingForms({ onComplete }: Props ): ReactElement{

  const { hasAuthenticated, shouldUserVerifyEmail: shouldVerifyEmail } = useAuth();
  const onboardingIdentity = useSelector( onboardingSelector );
  const userPerson = useSelector( userPersonSelector );
  const dispatch = useDispatch();

  const recaptchaRef = React.createRef() as React.RefObject<ReCAPTCHA>;

  const getItems: ( onboarding: OnboardingInterface )=> OnboardingQuestions = onboardingIdentity => {
    return[[ Authenticate , !onboardingIdentity.email || shouldVerifyEmail ],
      [ PromptForName, ( !onboardingIdentity.nameFirst || !onboardingIdentity.nameLast ) ],
      [ PromptForZip, !onboardingIdentity.addressData?.postalCode ],
      [ PromptForBirthday, !onboardingIdentity.birthDate ],
      [ PromptForGender, !onboardingIdentity.gender ]];
  };

  const [ items, setItems ] = useState<OnboardingQuestions>( getItems( onboardingIdentity )
    .filter( item => {
      return item[1];
    }));

  const getNextIndex: ( items: OnboardingQuestions )=> number  = items => {
    const computedNeedsList = JSON.stringify( items.map( item => item[1]));
    return JSON.parse( computedNeedsList ).findIndex( Boolean );
  };

  // TODO: update this to look better
  const shouldRenderSpinner = useSelector( state => state.onboarding?.createAccount?.meta?.requestStatus === 'pending' );
  const { currentIndex, retreat, proceed, setCurrentIndex } = useFlow( getNextIndex( items ));

  const onFormComplete = ( data: OnboardingInterface )=> {
    dispatch( saveOnboardingIdentity( data ));
    proceed();
  };

  const onCompleteCallback = useCallback( onComplete, [ onComplete ]);

  useEffect(() => {
    localStorage.setItem( 'isOnboarding', JSON.stringify( true ));
    return () => {
      localStorage.setItem( 'isOnboarding', JSON.stringify( false ));
    };
  }, []);

  const handleLoginError = ( error: string ) => {

    if ( error !== 'Please verify your email' ) {
      return dispatch( makeSnack({ theme:'error', message: 'Something went wrong' }));
    }

    // Save shouldVerifyEmail to true so Auth knows which prompt to render
    dispatch( shouldUserVerifyEmail( true ));

    const payload = {
      email: onboardingIdentity.email,
      additionalParameters: {
        source:'eppweb',
      },
    };

    // Send the login email to the new account
    dispatch( sendLoginEmail({
      ...payload ,
      onSuccess: () => {

        // Set the form index back to Auth
        setCurrentIndex( 0 );
      },
      onError: () => {
        dispatch( makeSnack({ theme:'error', message: 'Something went wrong' }));
      },
    }));
  };

  const createNewAccount = async() => {

    // Get the recaptcha value and add to onboarding identity
    const token = await recaptchaRef?.current?.executeAsync() ?? '';
    const recaptcha = { ...onboardingIdentity.recaptcha, ...{ token } };
    const onboarding = { ...onboardingIdentity, ...{ recaptcha } };

    dispatch( createAccount({
      onboardingIdentity: onboarding,

      // Handle account creation success
      onSuccess: response => {

        // Attempt to fetch the user
        dispatch( fetchUser({
          onSuccess: onCompleteCallback,
          onError: handleLoginError,
        }));
      },

      // Handle account creation error
      onError: () => {
        dispatch( makeSnack({ theme: 'error', message: 'Something went wrong' }));
        onCompleteCallback();
      },
    }));
  };

  // Side Effect -> setCurrentIndex - or if all requirements are met, set to -1
  useEffect(() => {

    if( currentIndex < 0 || currentIndex >= items.length ){

      // Only create the new account if we have NOT authenticated
      if( !hasAuthenticated ) {
        createNewAccount();
      } else {
        onCompleteCallback();
      }
    }
  }, [ currentIndex ]);

  //TODO: ensure this happens after we create the user
  useEffect(() => {
    const conditionalItems: OnboardingQuestions = [[ PromptForState, !userPerson?.address?.state ]];
    if( userPerson?.address?.zip && !userPerson?.address?.state ) {
      setItems([ ...items, ...conditionalItems ]);
    }
  }, [ userPerson?.address?.zip ]);

  return (
    <>
      <ReCAPTCHA
        size="invisible"
        ref={ recaptchaRef }
        sitekey={ appConfig.recaptcha.key }
        onErrored={ ()=> dispatch( makeSnack({ theme: 'error', message: 'Something went wrong please try again.' })) }
        badge="bottomleft"
      />
      <Flow
        currentIndex={ currentIndex }
      >
        {
          items.map(( item, idx ) => {
            const Item = item[0] as React.ElementType;
            return(
              <Item key={ `PromptForName.Item.${idx}` }
                onCancel={ idx > 0 ? retreat : () => alert( 'go back' )  }
                onFormComplete = { onFormComplete }
                setParentFlowIndex={ setCurrentIndex }
              />
            );
          })
        }
      </Flow>
      {shouldRenderSpinner && <div className="flex-1 flex flex-wrap items-center justify-center"><LoadingSpinner /></div>}
    </>
  );
}
