import { combineReducers } from 'redux';

// Reducers
import auth from './authReducer';
import backstack from './backstackReducer';
import config from './configReducer';
import documents from './documentsReducer';
import experiments from './experimentsReducer';
import goals from './goalsReducer';
import inheritances from './inheritancesReducer';
import invitations from './invitationsReducer';
import isLoading from './isLoadingReducer';
import modal from './modalReducer';
import people from './peopleReducer';
import properties from './propertiesReducer';
import roles from './rolesReducer';
import settings from './settingsReducer';
import snackbar from './snackbarReducer';
import translations from './translationsReducer';
import user from './userReducer';
import wordpress from './wordpressReducer';
import application from './applicationsReducer';
import powers from './powersReducer';
import directives from './directivesReducer';
import onboarding from './onboardingReducer';


/**
 * combineReducers(reducers)
 * https://redux.js.org/api/combinereducers
 *
 * The combineReducers helper function turns an object whose values are
 * different reducing functions into a single reducing function you can pass to
 * `createStore`.
 *
 * The resulting reducer calls every child reducer, and gathers their results
 * into a single state object. The state produced by combineReducers()
 * namespaces the states of each reducer under their keys as passed to
 * combineReducers()
 */


export const rootReducer = combineReducers({
  auth,
  backstack,
  config,
  documents,
  experiments,
  goals,
  inheritances,
  invitations,
  isLoading,
  modal,
  people,
  properties,
  powers,
  roles,
  settings,
  snackbar,
  translations,
  user,
  application,
  wordpress,
  directives,
  onboarding,
});

export default rootReducer;
