import { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

// Types
import SanitizedFormData from '../../../core/types/SanitizedFormData';

// Actions
import { makeSnack } from '../../store/reducers/snackbarReducer';

//import userSelector from '../../store/selectors/userSelector';
import analytics from '../../utils/analytics';

// Components
import Button from '../../components/Button';
import Translation from '../../components/Translation';
import { useDispatch, useSelector } from '../../store';
import onboardingSelector from '../../store/selectors/onboardingSelector';
import OnboardingInterface from '../../types/OnboardingInterface';
import { saveOnboardingIdentity } from '../../store/reducers/onboardingReducer';

interface PromptForVerificationProps {
  className?: string;
  onCancel?: ()=> void;
  onSuccess?: ()=> void;
  onFormComplete?: ( data: Partial<OnboardingInterface> )=> void;
}

const PromptForVerification: FC<PromptForVerificationProps> = ({
  className,
  onCancel,
  onSuccess,
  onFormComplete,
}) => {

  const onboardingIdentity = useSelector( onboardingSelector );
  const email = onboardingIdentity.email as string;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch( makeSnack({ theme: 'success', message: 'A login link has been sent to your email.' }));
    analytics.track( 'View - Registration Email Verification' );
  }, []);

  const sendNewCode = ()=> {
    dispatch( saveOnboardingIdentity({ ...onboardingIdentity,...{ email: '' } }));
    onCancel && onCancel();
  };

  return(
    <>
      <h1 className="text-3xl mb-10 text-black font-headline">
        <Translation translationKey={ 'account.signup.verify.email.title' } /> <span className="whitespace-nowrap">{ email }.</span>
      </h1>
      <p className="mb-6 text-black-50">
        <Translation translationKey={ 'onboarding.confirmation.email.subtitle' } />
      </p>
      <div>
        <Translation translationKey={ 'account.signup.code.resend.title' }/>
        <Button
          theme="clean"
          type="button"
          onClick={ sendNewCode } >{ <Translation translationKey={ 'account.signup.code.resend.link' } />}</Button>
      </div>
    </>
  );
};

export default PromptForVerification;
