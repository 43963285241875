import axios, { AxiosError, AxiosResponse } from 'axios';
import combineURLs from 'axios/lib/helpers/combineURLs';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import appConfig from '../../../core/appConfig';

import initialState from '../initial/onboarding';
import OnboardingInterface from '../../types/OnboardingInterface';
import WebClientRequest from '../../core-data-service/WebClientRequest';
import { fetchUser } from './userReducer';
import { RootState } from '..';

interface IPostLoginEmail {
  email: string;
  additionalParameters: {
        source: string;
  };
  onSuccess?: ( response: AxiosResponse )=> void;
  onError?: ( error: AxiosError )=> void;
}

/**
 * Send the login email to an existing Ethos account
 */
export const sendLoginEmail = createAsyncThunk(
  'account/sendLoginEmail',
  async({ onSuccess, onError, ...data }: IPostLoginEmail, thunkAPI ) => {
    return axios
      .post( combineURLs( appConfig.app.baseurl, '/api/sendLoginEmail' ), data )
      .then( response => {
        onSuccess && onSuccess( response );
      })
      .catch( error => {
        onError && onError( error );
      });
  },
);

type AxiosCreateAccountResponse = {
  identity: unknown;
  token: string;
}
interface IPutCreateAccount {
  onboardingIdentity: OnboardingInterface;
  onSuccess?: ( response: AxiosCreateAccountResponse )=> void;
  onError?: ( error: AxiosError )=> void;
}

/**
* Create a new account.
*/
export const createAccount = createAsyncThunk(
  'account/createAccount',
  async({ onSuccess, onError, ...data }: IPutCreateAccount, thunkAPI ) => {
    return axios
      .put( combineURLs( appConfig.app.baseurl, '/api/createAccount' ), data )
      .then(( response: AxiosResponse<AxiosCreateAccountResponse> ) => {

        // Store the token on the WebClientRequest
        WebClientRequest.token = response.data.token;
        onSuccess && onSuccess( response.data );
      })
      .catch( error => {
        onError && onError( error );
      });
  },
);

interface IPutLogin {
  singleUseToken: string;
  onSuccess?: ( response: AxiosResponse )=> void;
  onError?: ( error: unknown )=> void;
}

/**
* Create a new account.
*/
export const login = createAsyncThunk<
WebClientRequest,
IPutLogin,
{state: RootState}
>(
  'account/login',
  async({ onSuccess, onError, ...data }: IPutLogin, thunkAPI ) => {
    return axios
      .put( combineURLs( appConfig.app.baseurl, '/api/login' ), data )
      .then(( response: AxiosResponse<AxiosCreateAccountResponse> ) => {

        // Store the token on the WebClientRequest
        WebClientRequest.token = response.data.token;

        // Get the response data as onboardingIdentity
        const responseData = response.data as any;
        const onboardingIdentity: OnboardingInterface = {
          email: responseData.identity.emailAddress,
          nameFirst: responseData.identity.identity.nameFirst,
          nameLast: responseData.identity.identity.nameLast,
          birthDate: responseData.identity.identity.birthDate,
          gender: responseData.identity.identity.gender,
          addressData: responseData.identity.addressData,
          recaptcha: {
            userAction: '',
            token: '',
            type:'',
            source: '',
          },
        };

        // Save the onboardingIdentity
        thunkAPI.dispatch( saveOnboardingIdentity( onboardingIdentity ));

        // Fetch the user to trigger login completion
        thunkAPI.dispatch( fetchUser({
          onSuccess: response => {
            onSuccess && onSuccess( response );
          },
          onError: error => {
            onError && onError( error );
          },
        }));
      })
      .catch( error => {
        onError && onError( error );
      });
  },
);

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    saveOnboardingIdentity( onboarding, action: PayloadAction<OnboardingInterface> ){
      onboarding.data = { ...onboarding.data, ...action.payload };
    },
  },

  extraReducers: builder => {

    // Send Login Email
    builder.addCase( sendLoginEmail.pending, ( onboarding, action ) => {
      const { arg, ...meta } = action.meta;
      onboarding.sendLoginEmail.meta = meta;
    });
    builder.addCase( sendLoginEmail.fulfilled, ( onboarding, action ) => {
      const { arg, ...meta } = action.meta;
      onboarding.sendLoginEmail.meta = meta;
    });
    builder.addCase( sendLoginEmail.rejected, ( onboarding, action ) => {
      const { arg, ...meta } = action.meta;
      onboarding.sendLoginEmail.meta = meta;
    });

    // Create Account
    builder.addCase( createAccount.pending, ( onboarding, action ) => {
      const { arg, ...meta } = action.meta;
      onboarding.createAccount.meta = meta;
    });
    builder.addCase( createAccount.fulfilled, ( onboarding, action ) => {
      const { arg, ...meta } = action.meta;
      onboarding.createAccount.meta = meta;
    });
    builder.addCase( createAccount.rejected, ( onboarding, action ) => {
      const { arg, ...meta } = action.meta;
      onboarding.createAccount.meta = meta;
    });

    // Login
    builder.addCase( login.pending, ( onboarding, action ) => {
      const { arg, ...meta } = action.meta;
      onboarding.login.meta = meta;
    });
    builder.addCase( login.fulfilled, ( onboarding, action ) => {
      const { arg, ...meta } = action.meta;
      onboarding.login.meta = meta;
    });
    builder.addCase( login.rejected, ( onboarding, action ) => {
      const { arg, ...meta } = action.meta;
      onboarding.login.meta = meta;
    });
  },
});

export const { saveOnboardingIdentity } = onboardingSlice.actions;

export default onboardingSlice.reducer;
