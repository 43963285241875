import Analytics from 'analytics';
import segmentPlugin from '@analytics/segment';
import log from './log';
import appConfig from '../../core/appConfig';

// Types
import { Entitlement } from '../types/Entitlements/EntitlementInterface';
import { Keyable } from '../types/Keyable';
import axios, { AxiosResponse } from 'axios';

const VALID_AD_PARAMS = [
  'utm_source',
  'utm_campaign',
  'utm_medium',
  'utm_content',
  'utm_term',
  'e_ad_id',
  'e_adset_id',
  'e_campaign_id',
  'fbclid',
];


const isNotConfigured = !appConfig.segment.apiKey;
const isTesting = appConfig.app.env === 'test';


if( isNotConfigured && !isTesting ){
  console.warn( '[utils/analytics.ts] Segment is not configured' );
}

interface PayloadProperties {
  title: string;
  url: string;
  path: string;
  hash: string;
  search: string;
}
interface Payload {
  anonymousId?: string;
  properties: PayloadProperties;
  type: string;
  userId: string;
  event: string;
}

interface Opts {
  payload: Payload;
}

const logAnalytics = ({ payload }: Opts ) => {
  if( isTesting ){
    return;
  }

  const message = payload.type === 'pageStart'
    ? `[${( payload?.properties?.url || '' ).replace( /^https?:\/\//i, '' ) }]`
    : payload.type === 'trackStart' && Object.values( payload?.properties ).length
      ? `"${payload?.event}", ${JSON.stringify( payload?.properties )}`
      : '';

  log.orange(
    ` Analytics => ${
      ( payload?.type || '' ).replace( 'Start', '' )
    } ${message}`,
  );
};

export function debugAnalyticsPlugin(): {
  name: string;
  initializeStart: ( opts: Opts )=> void;
  pageStart: ( opts: Opts )=> void;
  trackStart: ( opts: Opts )=> void;
  identifyStart: ( opts: Opts )=> void;
  }{
  return {
    name: 'debug-analytics-to-console',
    initializeStart: logAnalytics,
    pageStart: logAnalytics,
    trackStart: logAnalytics,
    identifyStart: logAnalytics,
  };
}



const analytics = Analytics({
  app: 'web-app-v2',
  plugins: [
    ...(
      !isNotConfigured
        ? [ segmentPlugin({ writeKey: appConfig.segment.apiKey, customScriptSrc: appConfig.segment.customScriptSrc + '/analytics.js/v1/' + appConfig.segment.apiKey + '/analytics.min.js' }) ]
        : [ debugAnalyticsPlugin() ]),
  ],
});


export const getProductTrackingData = ( entitlement: Omit<Entitlement, 'isAccessible' | 'restrictions' | 'isSupported' | 'isUpgradeable' > ): Keyable => {
  // @TODO - FIX FOR EPP Release
  return {
    product: entitlement.isEnabled ? entitlement.upgrades[0]?.name.split( ' - ' )[0] : 'free',
    price: entitlement.isEnabled ? entitlement.upgrades[0]?.price : 0 };
};

export const filterAttributionParams = ( search: string ): string => {
  const currentQuery = new URLSearchParams( search );
  const filteredQuery = new URLSearchParams();

  Array.from( currentQuery.entries()).forEach( param => {
    const [ key, value ] = param;
    if( VALID_AD_PARAMS.includes( key )) {
      filteredQuery.set( key,value );
    }
  });

  return filteredQuery.toString();
};


export default analytics;
