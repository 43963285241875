//Components
import PersonIcon from '../views/People/PersonIcon';
import Modal from './Modal';

// Hooks
import useLenoxApplication from '../hooks/useLenoxApplication';
import useAuth from '../hooks/useAuth';
import { useDispatch, useSelector } from '../store';

// Redux
import { hideModal } from '../store/reducers/modalReducer';
import userSelector from '../store/selectors/userSelector';
import { GlobalModals } from '../store/initial/modal';
import Translation from './Translation';

export default function LenoxAdvisor(): JSX.Element{
  const isOpen = useSelector( state => state.modal.data.lenoxAdvisor.isOpen );
  const { lenoxAdvisor } = useLenoxApplication();
  const name = lenoxAdvisor.first_name + ' '+ lenoxAdvisor.last_name;

  const dispatch = useDispatch();
  const user = useSelector( userSelector );
  const hasAuthenticated = useAuth();
  const hasUserFinishedDisability = user.attributes['app.disability.hasCompletedPayment'] !== undefined && hasAuthenticated;


  return (
    <Modal isOpen={ isOpen }
      onClose={ ()=>{
        dispatch( hideModal( GlobalModals.lenoxAdvisor ));
      } }>

      <div className={ 'grid' }>
        <div><h1 className="text-black text-3xl font-headline"><Translation translationKey={ 'disability.advisor.modal.title' }/></h1></div>
        <div className="mt-4 mb-16"><Translation translationKey={ 'disability.advisor.modal.subtitle' }/></div>
        <div className="flex mb-16 items-center">
          <div className="w-16">
            <PersonIcon
              className="aspect-w-1 aspect-h-1"
              onImageLoadDuration={ 1 }
              photo_url={ null }
              alt={ name }
              personName={ name }/>
          </div>
          <div className={ 'ml-8' }>
            <div>
              <h1 className=" text-black font-bold">
                {name}
              </h1>
              <div >
                <a className={ 'text-forest' } href={ 'mailto:'+lenoxAdvisor.email } >{lenoxAdvisor.email}</a>
              </div>
              <div>
                <a className={ 'text-forest' } href={ 'tel:'+ lenoxAdvisor.phone }>{lenoxAdvisor.phone}</a>
              </div>
            </div>
          </div>
        </div>
        { hasUserFinishedDisability ?
          <div>
            <div><Translation translationKey={ 'disability.advisor.modal.help.payment.text' }/></div>
            <button type={ 'button' }><Translation translationKey={ 'disability.advisor.modal.help.payment.link' }/></button>
          </div>
          :<div>
            <div><Translation translationKey={ 'disability.advisor.modal.help.technical.text' }/></div>
            <div><a className={ 'text-forest' } href={ 'mailto:support@tomorrow.me' } ><Translation translationKey={ 'disability.advisor.modal.help.technical.link' }/></a></div>
          </div>
        }
      </div>
    </Modal>
  );
}
