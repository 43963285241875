import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// initialState
import initialState, { TranslationDict } from '../initial/translations';

// Utils
import WebClientRequest from '../../core-data-service/WebClientRequest';

interface ITranslationsPayload {
    name: 'en-US'; // TODO change as more languages supported
    data: TranslationDict;
    meta: {
      tests: TranslationDict;
    };
}


export const fetchTranslations = createAsyncThunk(
  'translations/fetchTranslations',
  async( _, thunkAPI ) => {
    const isoLanguageName = 'en-US';
    const url = `/v1/translations/${isoLanguageName}`;
    return WebClientRequest
      .get( url )
      .then( response => {
        const { meta, data } = response.data;
        if( !!response.data.data ){
          thunkAPI.dispatch( saveTranslations({
            name: isoLanguageName,
            data,
            meta,
          }));
        } else {
          throw new Error(
            '[translations/fetchTranslations] No translations in response',
          );
        }
      });
  },
);

const translationSlice = createSlice({
  name: 'translations',
  initialState,
  reducers: {

    saveTranslations( translations, action ){
      const { name, data, meta } = action.payload as ITranslationsPayload;
      Object.assign( translations.data[name], data );
      Object.assign( translations.meta, meta );
    },
  },
  extraReducers : builder => {
    builder.addCase( fetchTranslations.pending, ( translations, action )=> {
      const { arg, ...meta } = action.meta;
      translations.fetchTranslations.meta = meta;
    });
    builder.addCase( fetchTranslations.fulfilled, ( translations, action )=> {
      const { arg, ...meta } = action.meta;
      translations.fetchTranslations.meta = meta;
    });
    builder.addCase( fetchTranslations.rejected, ( translations, action )=> {
      const { arg, ...meta } = action.meta;
      translations.fetchTranslations.meta = meta;
    });
  },
});



export const { saveTranslations } = translationSlice.actions;

export default translationSlice.reducer;
