import { ReactElement, useEffect, useRef } from 'react';

// Components
import Button from '../../components/Button';
import ExternalDescriptiveLink from '../../components/ExternalDescriptiveLink';
import { Icons } from '../../components/Icon';
import List, { ListItemProps } from '../../components/List';
import Modal from '../../components/Modal';
import Translation from '../../components/Translation';

// Constants
import { WEB_PLATFORM } from '../../../core/constants';
import appConfig from '../../../core/appConfig';

// Hooks
import useBackstack from '../../hooks/useBackstack';
import useTranslations from '../../hooks/useTranslations';

// Types
import { InsuranceProviders } from '../../types/Insurance/LifeInsuranceProviders';
import { AxiosError, AxiosResponse } from 'axios';

// Utils
import analytics from '../../utils/analytics';
import { TranslationKeys } from '../../store/initial/translations';
import WebClientRequest from '../../core-data-service/WebClientRequest';

const LIST_ITEM_SETTINGS: {icon: Icons; iconClasses: string} = { icon: 'check', iconClasses: 'text-forest w-6 h-6 mr-5 flex-none' };
export interface EthosSimplifiedModalProps {
  isOpen?: boolean;
  quoteButtonOnClick: ( applicationUrl: string )=> void;
}

type LifeApplicationResponse = AxiosResponse<{data: {application_url: string}}>

interface ListItemChildrenProps {
  titleKey: TranslationKeys;
  descriptionKey: TranslationKeys;
}

const ListItemChildren = ({ titleKey, descriptionKey }: ListItemChildrenProps ): ReactElement => {
  return <>
    <p className="font-bold text-black mb-1"><Translation translationKey={ titleKey } /></p>
    <Translation translationKey={ descriptionKey } />
  </>;
};

const listItems: ListItemProps[] = [
  {
    ...LIST_ITEM_SETTINGS,
    children: <ListItemChildren titleKey="life.ethos.directReferral.bullet1.title" descriptionKey="life.ethos.directReferral.bullet1.subtitle" />,
  },
  {
    ...LIST_ITEM_SETTINGS,
    children: <ListItemChildren titleKey="life.ethos.directReferral.bullet2.title" descriptionKey="life.ethos.directReferral.bullet2.subtitle" />,
  },
  {
    ...LIST_ITEM_SETTINGS,
    children: <ListItemChildren titleKey="life.ethos.directReferral.bullet3.title" descriptionKey="life.ethos.directReferral.bullet3.subtitle" />,
  },
];

export default function EthosSimplifiedModal({ isOpen, quoteButtonOnClick }: EthosSimplifiedModalProps ): ReactElement{
  const { getTranslation } = useTranslations();
  const { goBack } = useBackstack();
  const { Ethos } = InsuranceProviders;

  const { ethos } = appConfig;

  const applicationUrl = useRef( ethos.baseUrl );

  const primaryAction = () => {
    quoteButtonOnClick( applicationUrl.current );
  };

  useEffect(() => {
    analytics.track( 'ViewQuoteCard', { Ethos });
  }, []);

  useEffect(() => {
    WebClientRequest
      .get( `/v1/insurance/apply?platform=${WEB_PLATFORM}` )
      .then(( response: LifeApplicationResponse ) => {
        applicationUrl.current = response.data.data.application_url;
      })
      .catch(( _error: AxiosError ) => {
        // do nothing
      });
  }, []);

  return (
    <Modal
      isOpen={ isOpen }
      onClose={ ()=> goBack() }
      contentClassName="bg-moss"
    >
      <div className="z-20 flex flex-col flex-1">
        <div className="flex justify-center mt-10" >
          <img src={ getTranslation( 'life.ethos.icon.heartShield' ) } alt="life insurance" className="w-1/6" />
        </div>
        <h1 className="text-lg text-center text-black font-headline mt-5">
          <Translation translationKey="lifeinsurance.intro.ethos.title" />
        </h1>
        <p className="text-center mx-auto mt-5 mb-8 w-4/5 sm:w-80 ">
          <Translation translationKey="life.ethos.directReferral.subtitle" />
        </p>
        <div className="flex flex-col items-center mb-8">
          <Button theme="primary" className="mb-4 w-full max-w-sm" onClick={ primaryAction } >
            <Translation translationKey="life.ethos.directReferral.button.title" />
          </Button>
          <ExternalDescriptiveLink
            className="mt-4 w-full max-w-sm"
            descriptionKey="lifeinsurance.intro.ethos.managepolicy.label"
            labelKey="lifeinsurance.intro.ethos.managepolicy.button"
            destinationUrl={ new URL( getTranslation( 'ethos.policy.signin.url.string.v2' ), ethos.baseUrl ).toString() }
          />
        </div>
        <div className="flex flex-col items-center">
          <List
            type="ul"
            uniqueKey="ethosLifeBenefits"
            itemClasses="mb-6 last:mb-0 w-full sm:w-96"
            items={ listItems }
          />
        </div>
      </div>
    </Modal>
  );
}
