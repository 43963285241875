import { FC, ReactElement, ReactNode, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import DocumentSectionOverview from '../../../components/DocumentSectionOverview';
import DocumentNextButton from '../DocumentNextButton';
import DocumentRoleSummaryItem from '../DocumentRoleSummaryItem';
import Helper from '../../../components/Helper';
import MissingPersonData from '../../../components/MissingPersonData';
import Modal from '../../../components/Modal';
import ProductUpsellHelper from '../../../components/ProductUpsellHelper';
import SegmentView from '../../../components/SegmentView';
import Translation from '../../../components/Translation';

// Hooks
import useAttorneyInFact from '../../../hooks/useAttorneyInFact';
import useMissingPersonData from '../../../hooks/useMissingPersonData';
import usePowers from '../../../hooks/usePowers';
import usePoaSectionStatus, {
  GENERAL_AUTHORITY,
  PoaSections,
  PoaSectionStates,
  PoaSectionTitleKey,
} from '../../../hooks/usePoaSectionStatus';
import useTranslations from '../../../hooks/useTranslations';

// Routes
import {
  dashboard as dashboardRoute,
  poa_attorney_in_fact as attorneyInFactRoute,
  poa_powers as poaPowersRoute,
  poa_special_instructions as poaSpecialInstructionsRoute,
  people as peopleRoute,
  poa_review as poaReviewRoute,
  checkout as checkoutRoute,
} from '../../../../core/routes';

// Redux
import productsSelector from '../../../store/selectors/productsSelector';
import { useDispatch, useSelector } from '../../../store';
import { fetchPowers } from '../../../store/reducers/powersReducer';
import { patchGoal, postGoal } from '../../../store/reducers/goalsReducer';
import { showModal } from '../../../store/reducers/modalReducer';
import { GlobalModals } from '../../../store/initial/modal';

// Types
import { GoalTypes } from '../../../types/GoalInterface';

// Utils
import { getFutureDate } from '../../../../core/utils/datetimeUtils';
import { generateQueryString } from '../../../utils/urlUtils';
import { getEntitlementNameKey } from '../../../utils/entitlementsUtils';
import { DOCUMENTS } from '../../../core-data-service/models/Document';



const Poa: FC = () => {

  const { push } = useHistory();
  const dispatch  = useDispatch();
  const { hasMissingData } = useMissingPersonData();
  const { attorneysInFact } = useAttorneyInFact();
  const products = useSelector( productsSelector );
  const { getTranslation } = useTranslations();

  const { sectionStates, next } = usePoaSectionStatus();
  const poaGoal = useSelector( state => state.goals.data.find( goal => goal.type === GoalTypes['GET POWER OF ATTORNEY']));


  useEffect(()=>{
    const onSuccess = ()=>{
      dispatch( fetchPowers());
    };
    if( !poaGoal ){
      dispatch( postGoal({ type: GoalTypes['GET POWER OF ATTORNEY'], onSuccess }));
    } else {
      dispatch( patchGoal({
        id: poaGoal.id,
        type: GoalTypes['GET POWER OF ATTORNEY'],
        expected_at: getFutureDate( 7 ),
        onSuccess,
      }));
    }
  },[ dispatch ]);

  const onPowersSectionClicked = () =>{
    dispatch( fetchPowers({ onSuccess: () => push( poaPowersRoute.get())  }));
  };

  const onPowersSpecialInstructionsClicked = () =>{
    dispatch( fetchPowers({ onSuccess: ()=> push( poaSpecialInstructionsRoute.get()) }));
  };

  const PoaActions = {
    attorneyInFact: {
      beginAction: () => push( attorneyInFactRoute.get()),
      detailsAction: () => push( attorneyInFactRoute.get()),
    },
    powers: {
      beginAction: onPowersSectionClicked,
      detailsAction: onPowersSectionClicked,
    },
    specialInstructions:{
      beginAction: onPowersSpecialInstructionsClicked,
      detailsAction: onPowersSpecialInstructionsClicked,
    },
    review: {
      beginAction: () => push( poaReviewRoute.get()),
      detailsAction: ()=> undefined,
    },
  };

  const AttorneyInFactString = (): ReactNode => {
    if ( sectionStates.attorneyInFact.state === 'default' ) {
      return <span><Translation translationKey="tile.poa.attorneyinfact.subtitle.noagent"/></span>;
    } else if( sectionStates.attorneyInFact.state !== 'complete' && sectionStates.attorneyInFact.state !== 'review' ) {
      return <Helper className="mt-0" theme={ sectionStates.attorneyInFact.state } body={ sectionStates.attorneyInFact.message && <Translation { ...sectionStates.attorneyInFact.message }/> } />;
    } else {
      return  attorneysInFact.map(( attorneyInFact, idx ) =>
        <DocumentRoleSummaryItem
          idx={ idx }
          key={ attorneyInFact.id }
          person={ attorneyInFact }
          roleTranslationKey="role.name.attorneyinfact.displayname"
        />,
      );
    }
  };

  return(
    <SegmentView
      contentClassName="pb-14 md:pb-36"
      title={ <Translation translationKey={ 'screen.poa.status.title' } /> }
      retreatIcon="close"
      hidePrimaryAction={ true }
      onRetreat={ () => push( dashboardRoute.get()) }
      secondaryActionChildren={
        <Translation translationKey="(button)help"/>
      }
      onSecondaryAction={ () => {
        dispatch( showModal( GlobalModals.help ));
      } }
    >
      <Modal
        isOpen={ hasMissingData }
      >
        <MissingPersonData title={ <Translation translationKey={ 'alert.document.missingdata.title' }/> }
          body={ <Translation translationKey={ 'alert.document.missingdata.body' }/> }
          primaryActionLabel={ <Translation translationKey={ 'alert.document.missingdata.button' }/> }
          onPrimaryAction={ ()=>push( peopleRoute.get()) }/>
      </Modal>

      <DocumentNextButton
        sectionLabelTranslationKey={ PoaSectionTitleKey( next ) }
        buttonTranslationKey={ next && sectionStates[next].state === 'error'
          ?'screen.poastatus.chapter.button.resolve'
          :'screen.poastatus.chapter.button.begin'
        }
        buttonAction={ sectionStates[next].state !== 'default'
          ? PoaActions[next].detailsAction
          : PoaActions[next].beginAction
        }
      />

      <DocumentSectionOverview
        state={ sectionStates.attorneyInFact.state }
        primaryActionId={'poa-attorneyInFact-section'}
        title={ <Translation translationKey={ PoaSectionTitleKey( PoaSections.attorneyInFact ) }/> }
        { ...PoaActions.attorneyInFact }
      >
        {AttorneyInFactString()}

      </DocumentSectionOverview>
      <DocumentSectionOverview
        state={ sectionStates.powers.state }
        primaryActionId={'poa-powers-section'}
        title={ <Translation translationKey={ PoaSectionTitleKey( PoaSections.powers ) }/> }
        { ...PoaActions.powers }
      >
        {PowersSummaryString( sectionStates )}
      </DocumentSectionOverview>
      <DocumentSectionOverview
        state={ sectionStates.specialInstructions.state }
        primaryActionId={'poa-specialInstructions-section'}
        title={ <Translation translationKey={ PoaSectionTitleKey( PoaSections.specialInstructions ) }/> }
        { ...PoaActions.specialInstructions }
      >
        {SpecialInstructionsSummaryString( sectionStates )}
      </DocumentSectionOverview>
      <DocumentSectionOverview
        title={ <Translation translationKey={ PoaSectionTitleKey( PoaSections.review ) }/> }
        isGated={ sectionStates.review.isGated }
        primaryActionId={'poa-reviewAndPrint-section'}
        className="!mb-0 !pb-0 !border-transparent"
        state={ sectionStates.review.state }
        { ...PoaActions.review }
      >
        { sectionStates.review.state === 'default' &&
          <span><Translation translationKey="status.tile.print.subtitle"/></span>
        }
      </DocumentSectionOverview>

      {!products.pro.hasPurchased &&
        <ProductUpsellHelper
          className="mt-8"
          title={ getTranslation( 'products.pro.chapter.print.not_started.card.title', [ getTranslation( getEntitlementNameKey( DOCUMENTS.power_of_attorney )) ]) }
          description={ getTranslation( 'products.pro.chapters.print.not_started.card.description' ) }
          primaryActionLabel={ getTranslation( 'products.pro.chapter.print.button', [ products.pro.price.displayValue ]) }
          primaryAction={ () => push({ pathname: checkoutRoute.get(), search: generateQueryString({ document: DOCUMENTS.power_of_attorney }) }) }
        />
      }

    </SegmentView>
  );
};

const PowersSummaryString = ( sectionStates: PoaSectionStates ): ReactElement => {
  const { powersWithoutInstructions } = usePowers();

  const grantedPowersCount = powersWithoutInstructions ? Object.values( powersWithoutInstructions ).filter( power => power ).length : 0;
  if( sectionStates.powers.state === 'complete' ) {
    if( powersWithoutInstructions[GENERAL_AUTHORITY]) {
      return <Translation translationKey= "tile.poa.powers.subtitle.summary.granted.all"/>;
    }else {
      return  <Translation
        translationKey= { grantedPowersCount === 1 ? 'tile.poa.powers.subtitle.summary.granted.singular' : 'tile.poa.powers.subtitle.summary.granted.plural' }
        dynamicStringValues={ [ grantedPowersCount.toString() ] }
      />;
    }
  }else {
    return <span><Translation translationKey= "tile.poa.powers.subtitle.unstarted"/></span>;
  }
};

const SpecialInstructionsSummaryString = ( sectionStates: PoaSectionStates ): ReactNode => {
  const { instructions } = usePowers();

  if( sectionStates.specialInstructions.state === 'default' ) {
    return <span><Translation translationKey="tile.poa.instructions.subtitle.unstarted"/> </span>;
  } else {
    return <p> {
      instructions ?
        <span className="line-clamp-3"> { instructions } </span> :
        <Translation translationKey="tile.poa.instructions.subtitle.summary"/>
    } </p>;
  }
};

export default Poa;


