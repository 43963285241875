import { FC, ReactElement, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

// Components
import FamilySectionBlock from './Blocks/FamilySectionBlock';
import DashboardDocuments from './DashboardDocuments';
import DashboardNotification from './DashboardNotification';
import Icon from '../../components/Icon';
import Translation from '../../components/Translation';

// Models
import Person from '../../core-data-service/models/Person';

// Hooks
import useExecutors from '../../hooks/useExecutors';
import useExperiments from '../../hooks/useExperiments';
import useGuardians from '../../hooks/useGuardians';
import useWillSectionStatus from '../../hooks/useWillSectionStatus';
import useAuth from '../../hooks/useAuth';

// Routes
import {
  sendInvites,
  whatIsTomorrow,
} from '../../../core/routes';

// Redux
import { useSelector } from '../../store';
import userPersonSelector from '../../store/selectors/userPersonSelector';
import productsSelector from '../../store/selectors/productsSelector';
import { ethosPartnerCodeSelector } from '../../store/selectors/userSelector';
import analytics from '../../utils/analytics';



interface DashboardProps {
  hasAuthenticated: boolean;
  hasCompletedOnboarding: boolean;
}

function Dashboard({ hasAuthenticated, hasCompletedOnboarding }: DashboardProps ): ReactElement{

  const { push, location } = useHistory();

  const { hasCompletedWill } = useWillSectionStatus();
  // user must have attributes to have started the will
  const hasFamily = useSelector( state => state.people.data ).filter( person => person.relationship !== 'ME' ).length > 0;
  const userPerson = useSelector( userPersonSelector );
  const hasEthosPartnerCode = useSelector( ethosPartnerCodeSelector );
  const { dashboardNotificationExperiment: { isVariantEnabled } } = useExperiments( 'DASHBOARD_NOTIFICATION_EXPERIMENT_KEY' );

  let firstName;
  if( userPerson ) {
    firstName = new Person( userPerson ).firstName ;
  }
  const isLoggedIn = hasAuthenticated;

  const { pro, basic } = useSelector( productsSelector );

  const { uninvitedExecutorFirstNames } = useExecutors();
  const { uninvitedGuardianFirstNames } = useGuardians();
  const combinedInviteeNames = Array.from( new Set([ ...uninvitedExecutorFirstNames, ...uninvitedGuardianFirstNames ]));
  const userLogInState = isLoggedIn ? 'signed in' : 'signed out';

  useEffect(() => {
    analytics.track( 'View - Homescreen', { context: userLogInState });
  }, []);

  return(
    <div className="container">
      <div>
        <h1
          className="text-3xl md:text-5xl font-headline text-black my-6 max-w-lg"
        >
          {isLoggedIn && firstName ?
            <Translation translationKey={ 'dashboard.signedin.title' } dynamicStringValues={ [ firstName ] }/> :
            <Translation translationKey={ hasEthosPartnerCode ? 'dashboard.signedout.title.ethos.policy.holder' : 'dashboard.signedout.title' }/> }<br/>
        </h1>
        {( !pro.hasPurchased && !basic.hasPurchased ) &&
          <h2 className="text-black-70 mb-8"><Translation translationKey="dashboard.signedin.subtitle"/></h2>
        }

        {!hasFamily &&
          <Link
            className="flex items-center my-6 md:my-8 font-bold hover:text-salamander w-max"
            to={{
              pathname: whatIsTomorrow.get(),
              state: { background: location },
            }}
          >
            <Icon className="text-salamander mr-2" name="play-circle" />
            <span className="text-black">
              {isLoggedIn ?
                <Translation translationKey={ 'dashboard.video_button' } /> :
                <Translation translationKey={ 'dashboard.signedout.video_button' } />
              }
            </span>
          </Link>
        }

        {
          isVariantEnabled &&
            ( hasCompletedWill && combinedInviteeNames.length > 0 ) &&
              <DashboardNotification
                title={ <Translation translationKey="home.invites.tile.title" /> }
                bodyText={ <Translation translationKey="home.invites.tile.description" /> }
                primaryActionText={ <Translation translationKey="home.invites.tile.button.title" /> }
                primaryAction={ ()=> push( sendInvites.get()) }
              />
        }

        {DashboardDocuments()}

        {hasAuthenticated && hasFamily &&
          <>
            <hr className="sm-only:-mx-8 border-t border-grey mb-8"/>
            <FamilySectionBlock className="mb-8"/>
          </>
        }

      </div>
    </div>
  );
}

const ConnectedDashboard: FC = () => {
  const { hasAuthenticated } = useAuth();
  const hasCompletedOnboarding = useSelector( state => state.auth.hasCompletedOnboarding );
  return(
    <Dashboard
      hasAuthenticated={ hasAuthenticated }
      hasCompletedOnboarding={ hasCompletedOnboarding }
    />
  );
};


export default ConnectedDashboard;
