import { ReactElement, useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm, UseFormMethods } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';

// Routes
import { dashboard as dashboardRoute } from '../../../core/routes';

// Components
import Button from '../../components/Button';
import Form from '../../components/Form';
import { RadioGroup } from '../../components/Radio';
import Translation from '../../components/Translation';
import { PURCHASE_PAGE } from '.';
import SegmentView from '../../components/SegmentView';

// Hooks
import useBackstack from '../../hooks/useBackstack';
import { useDispatch, useSelector } from '../../store';
import useTranslations from '../../hooks/useTranslations';

// Types
import SanitizedFormData from '../../../core/types/SanitizedFormData';
import { Product, PRODUCT_KEYS } from '../../types/ProductsInterface';

// Redux
import productsSelector from '../../store/selectors/productsSelector';

// Utils
import analytics from '../../utils/analytics';
import { generateQueryString } from '../../utils/urlUtils';
import { showContextualModal } from '../../store/reducers/modalReducer';
import { DocumentEntitlements } from '../../types/Entitlements/EntitlementInterface';
import { getEntitlementNameKey } from '../../utils/entitlementsUtils';
import { BASIC_DOCUMENTS } from '../../core-data-service/models/Document';

interface IPricingSelectionView {
  selectedProduct: keyof Product;
  document: keyof DocumentEntitlements;
  onSubmit: ( product: keyof Product )=> void;
}

const SELECTION_RADIO_GROUP_NAME = 'plan';

export default function PricingSelectionView({ document, selectedProduct, onSubmit }: IPricingSelectionView ): ReactElement{

  const { goBack } = useBackstack( dashboardRoute.get());
  const { push } = useHistory();
  const { getTranslation } = useTranslations();
  const location = useLocation();
  const dispatch = useDispatch();
  const products = useSelector( productsSelector );

  // Form
  const { register, unregister, setValue, getValues, errors, handleSubmit, watch } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      plan: selectedProduct,
    },
    shouldUnregister: false,
  });

  // Package change warning modal
  const initialSelection = useRef( selectedProduct );
  const currentSelection = watch( SELECTION_RADIO_GROUP_NAME );
  const [ hasSeenChangeModal, setHasSeenChangeModal ] = useState( false );
  const shouldShowChangeModal = initialSelection.current === PRODUCT_KEYS.pro && initialSelection.current !== currentSelection;
  useEffect(() => {
    if( !hasSeenChangeModal && shouldShowChangeModal && !BASIC_DOCUMENTS.includes( document )) {
      dispatch( showContextualModal({
        description:
          getTranslation( 'alert.document.not.included.in.package.message',
            [
              getTranslation( getEntitlementNameKey( document )),
              getTranslation( 'products.estateplan.paywall.pro.title' ),
              getTranslation( 'products.estateplan.paywall.basic.title' ),
            ],
          ),
        confirmLabel: getTranslation( 'alert.document.not.included.in.package.button.stick.with.pro' ),
        dismissLabel: getTranslation( 'alert.document.not.included.in.package.button.choose.basic' ),
        onConfirm: () => {
          setValue( SELECTION_RADIO_GROUP_NAME, PRODUCT_KEYS.pro );
          setHasSeenChangeModal( true );
        },
        onDismiss: () => setHasSeenChangeModal( true ),
      }));
    }
  }, [ hasSeenChangeModal, shouldShowChangeModal, dispatch, document, setValue ]);


  const useFormProps = {
    register: register({ required: 'You must answer this question' }) as UseFormMethods['register'],
    unregister,
    setValue,
    errors,
    getValues,
    defaultValues: {
      plan: selectedProduct,
    },
  };

  useEffect(() => {
    analytics.track( 'View - Plan Screen', {
      currentSelection: selectedProduct,
    });
  }, [ selectedProduct ]);

  const onFormSubmit: SubmitHandler<SanitizedFormData> = data => {
    const { plan } = data;
    onSubmit( plan as keyof Product );
    analytics.track( 'Touch - Plan Screen', { currentSelection: plan });

    push({
      pathname: location.pathname,
      search: generateQueryString({
        page: PURCHASE_PAGE,
        product: plan,
        document,
      }),
    });
  };

  return (
    <SegmentView
      full
      hidePrimaryAction
      onRetreat={ goBack }
      contentClassName="sm-only:bg-grey-400 overflow-y-auto pb-8 container--lg pt-24 sm-only:pt-18"
    >
      <div className="md:container--sm flex flex-col gap-8 md:gap-12">
        <h1 className="text-3xl md:text-5xl text-center font-headline"><Translation translationKey="products.estateplan.paywall.title" /></h1>
        <Form handleSubmit={ handleSubmit } onSubmit={ onFormSubmit } className="grid gap-10 md:gap-12 md:justify-items-center">
          <RadioGroup
            items={
              [
                {
                  label: <div className="flex flex-col h-full justify-between gap-6">
                    <div>
                      <h2 className="font-bold mb-2"><Translation translationKey="products.estateplan.paywall.basic.title"/></h2>
                      <p><Translation translationKey="products.estateplan.paywall.basic.description"/></p>
                    </div>
                    <p><span className="text-forest bg-moss rounded-sm p-1 mt-1">{products[PRODUCT_KEYS.basic].price.displayValue}</span></p>
                  </div>,
                  value: PRODUCT_KEYS.basic,
                },
                { label: <div className="flex flex-col h-full justify-between gap-6">
                  <div>
                    <h2 className="font-bold mb-2"><Translation translationKey="products.estateplan.paywall.pro.title"/></h2>
                    <p><Translation translationKey="products.estateplan.paywall.pro.description"/></p>
                  </div>
                  <p>
                    <span className="text-forest bg-moss rounded-sm p-1 mt-1">
                      {products[PRODUCT_KEYS.pro].price.displayValue}
                    </span>
                  </p>
                </div>,
                value: PRODUCT_KEYS.pro,
                },
              ]
            }
            label="Choose your estate plan"
            showLabel={ false }
            itemsFullWidth
            name={ SELECTION_RADIO_GROUP_NAME }
            itemsClassName="bg-white rounded -mt-4"
            className="mt-4 estate-plan-select"
            wrapItemChildren
            { ...useFormProps }
          />
          <Button id="continue-payment-button" type="submit" className="shadow-button md:max-w-sm md:px-32">
            <Translation translationKey="products.estateplan.paywall.button.continue"/>
          </Button>
        </Form>
      </div>
    </SegmentView>
  );
}
