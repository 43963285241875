interface AuthState {
  access_token?: string;
  hasAuthenticated: boolean;
  hasCompletedOnboarding: boolean;
  shouldUserVerifyEmail: boolean;
}

const initialState = {
  access_token: window.access_token,
  hasAuthenticated: false,
  hasCompletedOnboarding: false,
  shouldUserVerifyEmail: false,
};


export default initialState;
