import { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import DocumentSectionOverview from '../../../components/DocumentSectionOverview';
import { getHeirsInitialScenarioPage, heirsVideoSourceMap } from '../../Heirs';
import Helper from '../../../components/Helper';
import MissingPersonData from '../../../components/MissingPersonData';
import Modal from '../../../components/Modal';
import SegmentView from '../../../components/SegmentView';
import Translation from '../../../components/Translation';
import DocumentRoleSummaryItem from '../DocumentRoleSummaryItem';
import WillGuardiansSummary from './WillGuardiansSummary';
import WillPropertySummary from './WillPropertySummary';
import DocumentSectionProfiles from '../DocumentSectionProfiles';
import DocumentNextButton from '../DocumentNextButton';
import DocumentBasicsSummary from '../DocumentBasicsSummary';
import ProductUpsellHelper from '../../../components/ProductUpsellHelper';

// helper
import { getPropertiesUniqueBeneficiaries } from '../../../utils/properties/getBeneficiariesFromProperties';

// Hooks
import useExecutors from '../../../hooks/useExecutors';
import useGuardians from '../../../hooks/useGuardians';
import useHeirs from '../../../hooks/useHeirs';
import useMissingPersonData from '../../../hooks/useMissingPersonData';
import useProperties from '../../../hooks/useProperties';
import useWillSectionStatus, { WillSections } from '../../../hooks/useWillSectionStatus';
import useUserAttributes from '../../../hooks/useUserAttributes';
import { GetWillSectionTitleKey } from '../../../hooks/useWillSectionStatus';
import useTranslations from '../../../hooks/useTranslations';
import useHasPurchasedAndCompletedTrust from '../../../hooks/useHasPurchasedAndCompletedTrust';

// Redux
import { setUserAttributes } from '../../../store/reducers/userReducer';
import { showContextualModal, showModal, showVideoModal } from '../../../store/reducers/modalReducer';
import { useDispatch, useSelector } from '../../../store';
import productsSelector from '../../../store/selectors/productsSelector';
import { getEntitlementNameKey } from '../../../utils/entitlementsUtils';
import { GlobalModals } from '../../../store/initial/modal';

// Routes
import {
  dashboard as dashboardRoute,
  will_basics as basicsRoute,
  will_heirs as heirsRoute,
  will_guardians as guardiansRoute,
  will_valuables as valuablesRoute,
  will_realEstate as realEstateRoute,
  will_executors as executorsRoute,
  people as peopleRoute,
  will_pets as petsRoute,
  trust as trustRoute,
  pourover_will as pourOverWillRoute,
  will_review,
  checkout as checkoutRoute,
} from '../../../../core/routes';

// Types
import Property from '../../../core-data-service/models/Property';
import Person from '../../../core-data-service/models/Person';
import { DOCUMENTS } from '../../../core-data-service/models/Document';
import { SESSION_STORAGE_POUR_OVER_WILL } from '../../../../core/constants';
import { valuablesVideoSourceMap } from '../../Property/Valuables/ValuablesListView';
import { executorsVideoSourceMap } from '../../Executors/ExecutorsSummary';
import { guardiansVideoSourceMap } from '../../Guardians/GuardiansSummary';
import { generateQueryString } from '../../../utils/urlUtils';


const Will: FC = () => {

  const { push } = useHistory();
  const dispatch = useDispatch();

  const { hasMissingData } = useMissingPersonData();
  const userAttributes = useSelector( state => state.user.data.attributes );
  const products = useSelector( productsSelector );

  const { executors } = useExecutors();
  const { hasAddedUnderageChildren } = useGuardians();
  const { heirs } = useHeirs();
  const { pets, valuables, realEstate } = useProperties();
  const { getTranslation } = useTranslations();
  const hasPurchasedAndCompletedTrust = useHasPurchasedAndCompletedTrust();

  const { next, sectionStates } = useWillSectionStatus();

  //@TODO remove this code, backstack should be clean enought to know where to go
  const fromPourOverWill = sessionStorage.getItem( SESSION_STORAGE_POUR_OVER_WILL );
  const onRetreat = fromPourOverWill ? () => push( pourOverWillRoute.get()) : () => push( dashboardRoute.get());

  const initialHeirsRoute = heirsRoute.get({}, { page: getHeirsInitialScenarioPage( userAttributes ) });

  // this could be a bug in the future, but we are mutating the output of this hook just for this will view,
  // preventing users from entering this section but not allowing this to affect the document status.
  if ( hasPurchasedAndCompletedTrust ) {
    sectionStates.heirs = {
      ...sectionStates.heirs,
      message: {
        translationKey: 'tile.heir.status.redirecttotrust.message',
      },
      isGated: true,
    };
  }

  const hasMinors = userAttributes['app.hasUnderageChildren'] || hasAddedUnderageChildren;

  const { documents: { will: {
    shouldBeReviewed,
    hasViewedHeirsVideo,
    hasViewedGuardiansVideo,
    hasViewedValuablesVideo,
    hasViewedExecutorsVideo,
  } } } = useUserAttributes();

  useEffect(() => {
    if( shouldBeReviewed ) {
      dispatch( showContextualModal({
        title: getTranslation( 'products.will.needsreview.title' ),
        description: getTranslation( 'products.will.needsreview.description' ),
        confirmLabel: getTranslation( 'products.will.needsreview.confirm' ),
        onConfirm: () => dispatch( setUserAttributes({ 'app.willNeedsReview': false })),
        contentClassName: '!bg-moss',
        iconAboveHeader: 'alert-circle',
      }));
    }
  }, [ dispatch ]);

  const WillActions = {
    basics: {
      beginAction: () => push( basicsRoute.get()),
      detailsAction: () => push( basicsRoute.get()),
    },
    heirs: {
      beginAction: () => {
        !hasViewedHeirsVideo
          ? dispatch( showVideoModal({
            src: heirsVideoSourceMap,
            hasViewedKey: 'app.viewedHeirsVideo',
            onSkip: () => push( initialHeirsRoute ),
            onEnded: () => push( initialHeirsRoute ),
          }))
          : push( initialHeirsRoute );
      },
      detailsAction: () => push( initialHeirsRoute ),
    },
    guardians: {
      beginAction: () => {
        !hasViewedGuardiansVideo
          ? dispatch( showVideoModal({
            src: guardiansVideoSourceMap,
            onSkip: () => push( guardiansRoute.get()) ,
            onEnded: () => push( guardiansRoute.get()),
            hasViewedKey: 'app.viewedGuardiansVideo',
          }))
          : push( guardiansRoute.get());
      },
      detailsAction: () => push( guardiansRoute.get()),
    },
    valuables: {
      beginAction:
        () => {
          !hasViewedValuablesVideo
            ? dispatch( showVideoModal({
              src: valuablesVideoSourceMap,
              hasViewedKey: 'app.viewedValuablesVideo',
              onSkip: () => push( valuablesRoute.get()),
              onEnded: () => push( valuablesRoute.get()),
            }))
            : push( valuablesRoute.get());
        },
      detailsAction: () => push( valuablesRoute.get()),
    },
    realEstate: {
      beginAction: () => {
        push( realEstateRoute.get());
        dispatch( setUserAttributes({ 'app.willRealEstateFinished': true }));
      },
      detailsAction: () => push( realEstateRoute.get()),
    },
    pets: {
      beginAction: () => push( petsRoute.get()),
      detailsAction: () => push( petsRoute.get()),
    },
    executors: {
      beginAction:  () => {
        !hasViewedExecutorsVideo
          ? dispatch( showVideoModal({
            src: executorsVideoSourceMap,
            hasViewedKey: 'app.viewedExecutorsVideo',
            onSkip: () => push( executorsRoute.get()),
            onEnded: () => push( executorsRoute.get()),
          }))
          : push( executorsRoute.get());
      },
      detailsAction: () => push( executorsRoute.get()),
    },
    review: {
      beginAction: () => push( will_review.get()),
      detailsAction: () => push( will_review.get()),
    },
  };

  return(
    <SegmentView
      contentClassName="pb-14 md:pb-36"
      title={ <Translation translationKey={ 'screen.will.status.title' } /> }
      retreatIcon="close"
      hidePrimaryAction={ true }
      onRetreat={ onRetreat }
      secondaryActionChildren={
        <Translation translationKey="(button)help"/>
      }
      onSecondaryAction={ () => {
        dispatch( showModal( GlobalModals.help ));
      } }
    >
      <Modal
        isOpen={ hasMissingData }
      >
        <MissingPersonData title={ <Translation translationKey={ 'alert.document.missingdata.title' }/> }
          body={ <Translation translationKey={ 'alert.document.missingdata.body' }/> }
          primaryActionLabel={ <Translation translationKey={ 'alert.document.missingdata.button' }/> }
          onPrimaryAction={ ()=>push( peopleRoute.get()) }/>
      </Modal>

      <DocumentNextButton
        sectionLabelTranslationKey={ GetWillSectionTitleKey( next ) }
        buttonTranslationKey={ next && sectionStates[next].state === 'error'
          ?'screen.willstatus.chapter.button.resolve'
          :'screen.willstatus.chapter.button.begin'
        }
        buttonAction={ sectionStates[next].state !== 'default'
          ? WillActions[next].detailsAction
          : WillActions[next].beginAction
        }
      />

      <DocumentSectionOverview
        state={ sectionStates.basics.state }
        primaryActionId={'will-basics-section'}
        title={ <Translation translationKey={ GetWillSectionTitleKey( WillSections.basics ) }/> }
        { ...WillActions.basics }
      >
        <DocumentBasicsSummary />
      </DocumentSectionOverview>

      <DocumentSectionOverview
        state={ sectionStates.heirs.state }
        primaryActionId={'will-heirs-section'}
        title={ <Translation translationKey={ GetWillSectionTitleKey( WillSections.heirs ) }/> }
        isGated={ sectionStates.heirs.isGated }
        isNotManagedInThisDocument={ hasPurchasedAndCompletedTrust }
        { ...WillActions.heirs }
      >
        {/* @TODO there seems to be a disconnect with the section states between web/iOS here, iOS does not have error */}
        {!hasPurchasedAndCompletedTrust && !!heirs.length && <DocumentSectionProfiles
          profiles={ heirs.map( heir => {
            return { src: heir.photo_url || '', name: heir.name || '' };
          }) }
          firstNames={
            heirs.map( heir => {
              const firstName = new Person( heir ).firstName;
              return ( firstName );
            })
          }
        />}
        {( sectionStates.heirs.state === 'warning' || sectionStates.heirs.state === 'error' || hasPurchasedAndCompletedTrust ) &&
          <Helper
            className="mt-0"
            theme={ hasPurchasedAndCompletedTrust ? 'unmanaged' : sectionStates.heirs.state }
            body={ sectionStates.heirs.message && <Translation { ...sectionStates.heirs.message }/> }
            primaryActionLabel={ hasPurchasedAndCompletedTrust && <Translation translationKey="tile.heir.status.redirecttotrust.button"/> }
            primaryAction={ hasPurchasedAndCompletedTrust ? () => push( trustRoute.get()) : undefined }
          />
        }

      </DocumentSectionOverview>


      <DocumentSectionOverview
        title={ <Translation translationKey={ GetWillSectionTitleKey( WillSections.executors ) }/> }
        state={ sectionStates.executors.state }
        primaryActionId={'will-executors-section'}
        { ...WillActions.executors }
      >
        {
          !!executors.length ? executors
            .map(( executor, idx ) =>
              <DocumentRoleSummaryItem
                idx={ idx }
                key={ executor.id }
                person={ executor }
                roleTranslationKey="role.name.executor.displayname"
              />,
            )
            : sectionStates.executors.message && <Helper
              className="mt-0"
              theme={ sectionStates.executors.state }
              body={ <Translation { ...sectionStates.executors.message }/> }
            />
        }
      </DocumentSectionOverview>

      {hasMinors &&
        <DocumentSectionOverview
          state={ sectionStates.guardians.state }
          primaryActionId={'will-guardians-section'}
          title={ <Translation translationKey={ GetWillSectionTitleKey( WillSections.guardians ) }/> }
          { ...WillActions.guardians }
        >
          {( sectionStates.guardians.state !== 'complete' && sectionStates.guardians.state !== 'default' ) ?
            <Helper
              className="mt-0"
              theme={ sectionStates.guardians.state }
              body={ sectionStates.guardians.message && <Translation { ...sectionStates.guardians.message } /> }
            />
            :
            sectionStates.guardians.message
              ? <Translation { ...sectionStates.guardians.message } />
              :<WillGuardiansSummary />
          }
        </DocumentSectionOverview>
      }

      <DocumentSectionOverview
        title={ <Translation translationKey={ GetWillSectionTitleKey( WillSections.pets ) }/> }
        state={ sectionStates.pets.state }
        primaryActionId={'will-pets-section'}
        { ...WillActions.pets }
      >
        {sectionStates.pets.state === 'error' &&
          <Helper
            className="mt-0"
            theme={ sectionStates.pets.state }
            body={ sectionStates.pets.message && <Translation { ...sectionStates.pets.message } /> }
          />
        }
        {( sectionStates.pets.state !== 'default' && sectionStates.pets.state !== 'error' ) &&
          ( pets.length ?
            <DocumentSectionProfiles
              profiles={
                pets.map( pet => {
                  return { src: Property.getIcon( pet.subtype ), name: pet.name || pet.subtype };
                })
              }
            />
            :
            <Translation translationKey="products.will.pets.status.empty" />
          )
        }
      </DocumentSectionOverview>

      <DocumentSectionOverview
        state={ sectionStates.valuables.state }
        primaryActionId={'will-valuables-section'}
        title={ <Translation translationKey={ GetWillSectionTitleKey( WillSections.valuables ) }/> }
        { ...WillActions.valuables }
      >
        <WillPropertySummary
          propertyLabel="valuables"
          beneficiaries={ getPropertiesUniqueBeneficiaries( valuables ) }
          sectionState={ sectionStates.valuables.state }
          fallbackTranslationKey={ valuables.length
            ? 'tile.will.valuables.status.subtitle.nobeneficiaries'
            :  'tile.will.valuables.status.subtitle.nobelongings'
          }
        />
      </DocumentSectionOverview>

      <DocumentSectionOverview
        state={ sectionStates.realEstate.state }
        primaryActionId={'will-realEstate-section'}
        title={ <Translation translationKey={ GetWillSectionTitleKey( WillSections.realEstate ) }/> }
        { ...WillActions.realEstate }
      >
        <WillPropertySummary
          propertyLabel="real estate"
          beneficiaries={ getPropertiesUniqueBeneficiaries( realEstate ) }
          sectionState={ sectionStates.realEstate.state }
          fallbackTranslationKey={ realEstate.length
            ? 'tile.will.realestate.status.subtitle.nobeneficiaries'
            : 'tile.will.realestate.status.subtitle.norealestate'
          }
        />
      </DocumentSectionOverview>

      <DocumentSectionOverview
        title={ <Translation translationKey={ GetWillSectionTitleKey( WillSections.review ) }/> }
        isGated={ sectionStates.review.isGated }
        className="!mb-0 !pb-0 !border-transparent"
        primaryActionId={'will-reviewAndPrint-section'}
        state={ sectionStates.review.state }
        { ...WillActions.review }
      />

      {( !products.basic.hasPurchased ) &&
        <ProductUpsellHelper
          className="mt-8"
          title={ getTranslation( 'products.basic.chapter.print.not_started.card.title', [ getTranslation( getEntitlementNameKey( DOCUMENTS.will )) ]) }
          description={ getTranslation( 'products.basic.chapters.print.not_started.card.description' ) }
          primaryActionLabel={ getTranslation( 'products.basic.chapter.print.button', [ products.basic.price.displayValue ]) }
          primaryAction={ () => push({ pathname: checkoutRoute.get(), search: generateQueryString({ document: DOCUMENTS.will }) }) }
        />
      }
    </SegmentView>
  );
};


export default Will;
