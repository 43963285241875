import { useSelector } from '../store';

interface UseAuth {
  hasAuthenticated: boolean;
  hasCompletedOnboarding: boolean;
  shouldUserVerifyEmail: boolean;
}

export default function useAuth(): UseAuth{

  // Represents if the user is authenticated AND has provided all the necessary onboarding data
  const hasCompletedOnboarding = useSelector( state => !!state.auth?.hasCompletedOnboarding );

  // Represents if the user has successfully created or fetched and ethos account and is able to communicate with the CDS
  const hasAuthenticated = useSelector( state => !!state.auth?.hasAuthenticated );

  // Represents if an EPP user still needs to verify their email after creating an Ethos account
  const shouldUserVerifyEmail = useSelector( state => !!state.auth?.shouldUserVerifyEmail );

  return {
    hasCompletedOnboarding,
    hasAuthenticated,
    shouldUserVerifyEmail,
  };
}
