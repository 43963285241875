import { RootState } from '..';

// Types
import { ObjectEntries } from '../../types/helpers';
import { Entitlements, DocumentEntitlements } from '../../types/Entitlements/EntitlementInterface';

// Utils
import { isDocumentSupported } from '../../utils/entitlementsUtils';
import { loggedOutDefaultEntitlements } from '../../types/Entitlements/DefaultEntitlements';

interface IEntitlementsSelector extends Entitlements {
  availableDocuments: Array<keyof DocumentEntitlements>;
  unavailableDocuments: Array<keyof DocumentEntitlements>;
}

export const entitlementsSelector = ( state: RootState ): IEntitlementsSelector => {

  const { insurance, documents } = state.user.data?.entitlements || loggedOutDefaultEntitlements;

  const normalizedDocuments = ObjectEntries( documents ).reduce(( acc, [ key, value ]) => {
    acc[key] = {
      isAccessible: ( value.feature !== 'unavailable' ),
      isEnabled: value.feature === 'enabled',
      isSupported: isDocumentSupported( key as keyof DocumentEntitlements ),
      upgrades: value.upgrades || [],
      restrictions: value.restrictions || [],
    };
    return acc;
  }, {} as DocumentEntitlements );

  const documentsAvailability = ObjectEntries( normalizedDocuments ).reduce(( acc, [ document, entitlements ]) => {
    if ( entitlements.isAccessible ) {
      acc.available.push( document as keyof DocumentEntitlements );
    } else {
      acc.unavailable.push( document as keyof DocumentEntitlements );
    }
    return acc;
  }, { available: [] as Array<keyof DocumentEntitlements>, unavailable: [] as Array<keyof DocumentEntitlements> });

  return {
    ...normalizedDocuments,
    insurance: {
      life: insurance?.life,
      policy: insurance?.policy,
      disability: insurance?.disability,
    },
    availableDocuments: documentsAvailability.available,
    unavailableDocuments: documentsAvailability.unavailable,
  };
};

export default entitlementsSelector;
