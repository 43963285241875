import { FC, ChangeEvent } from 'react';
import { UseFormMethods, FieldErrors, Validate, RegisterOptions } from 'react-hook-form';
import FieldError from './FieldError';
import FieldLabel from './FieldLabel';

type enterKeyHint = 'enter' | 'done' | 'go' | 'next' | 'previous' | 'search' | 'send';
declare module 'react' {
  interface InputHTMLAttributes<T> extends HTMLAttributes<T> {
    enterkeyhint?: enterKeyHint;
  }
}

export interface TextFieldProps {
  autoFocus?: boolean;
  autoComplete?: 'on'|'off';
  children?: never;
  className?: string;
  isDarkBg?: boolean;
  disabled?: boolean;
  errors: FieldErrors;
  label: string|React.ReactNode;
  maxLength?: number;
  minLength?: RegisterOptions['minLength'];
  name: string;
  large?: boolean;
  normalize?: ( inputValue: string )=> string;
  placeholder?: string;
  register: UseFormMethods['register'];
  required?: RegisterOptions['required'];
  pattern?: RegisterOptions['pattern'];
  setValue: UseFormMethods['setValue'];
  showLabel?: boolean;
  step?: string;
  style?: React.CSSProperties;
  type?: 'text' | 'email' | 'number' | 'tel' | 'password';
  validate?: Validate;
  inputClasses?: string;
  inputMode?:  'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search';
}

export const TextField: FC<TextFieldProps> = ({
  autoFocus,
  autoComplete='on',
  className = '',
  isDarkBg=false,
  errors,
  disabled,
  inputClasses='',
  label,
  large,
  maxLength,
  minLength = 0,
  name,
  normalize,
  placeholder = '',
  register,
  pattern,
  required = false,
  setValue,
  showLabel = true,
  step,
  style,
  type = 'text',
  validate,
  inputMode,
}) => {

  /** Change handler - can normalize/mask the input if given normalize function */
  const onChange = ( event: ChangeEvent ) => {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;
    if ( normalize instanceof Function ) {
      return setValue( name, normalize( value ));
    }
    return setValue( name, value );
  };

  const registerOptions = {
    maxLength,
    minLength,
    required,
    pattern,
    validate,
  } as RegisterOptions;

  const fieldError = errors[name];

  return (
    <FieldLabel
      label={ label }
      name={ name }
      isLarge={ large }
      isHidden={ !showLabel }
      className={ className }
    >
      <input
        className={ `input ${inputClasses} ${ isDarkBg ? 'input--dark' : ''}` }
        maxLength={ maxLength }
        name={ name }
        onChange={ onChange }
        placeholder={ placeholder }
        ref={ register( registerOptions ) }
        style={{ ...style }}
        step={ step }
        type={ type }
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={ autoFocus }
        aria-invalid={ !!fieldError }
        inputMode={ inputMode }
        autoComplete={ autoComplete }
        disabled={ disabled }
      />
      {
        !!fieldError &&
        <FieldError
          className="mt-2"
          errorMessage={ fieldError.message }
        />
      }
    </FieldLabel>
  );
};


export default TextField;
