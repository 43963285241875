import { ReactElement } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

// Views
import MedicalConsent from '../../../views/Documents/MedicalConsent';
import MedicalConsentDoctorManagement from '../../../views/Documents/MedicalConsent/CareInformation/Doctors';
import MedicalConsentMedicationsForm from '../../../views/Documents/MedicalConsent/CareInformation/MedicalConsentMedicationsForm';
import MedicalConsentAllergiesForm from '../../../views/Documents/MedicalConsent/CareInformation/MedicalConsentAllergiesForm';
import MedicalConsentDocumentManagement from '../../../views/Documents/MedicalConsent/DocumentManagement';
import MedicalConsentCareInformationManagement from '../../../views/Documents/MedicalConsent/CareInformation';
import PersonDetailView from '../../../views/People/PersonDetailView';
import { withPersonDetailParam } from '../../../views/People/PersonDetailParamWrapper';

// Hooks
import useAuth from '../../../hooks/useAuth';

// Routes
import {
  dashboard as dashboardRoute,
  medical_consent as medicalConsentRoute,
  medical_consent_document as medicalConsentDocumentRoute,
  medical_consent_care_info as medicalConsentCareInfoRoute,
  medical_consent_caretaker as medicalConsentCaretakerRoute,
  medical_consent_address as medicalConsentAddressRoute,
  medical_consent_medications as medicalConsentMedicationsRoute,
  medical_consent_allergies as medicalConsentAllergiesRoute,
  medical_consent_doctors as medicalConsentDoctorsRoute,
  login as loginRoute,
} from '../../../../core/routes';

// Utils
import { generateQueryString } from '../../../utils/urlUtils';
import MedicalConsentAddressForm from '../../../views/Documents/MedicalConsent/CareInformation/MedicalConsentAddress';

export default function MedicalConsentRouter(): ReactElement{
  const { hasCompletedOnboarding } = useAuth();
  const location = useLocation();
  const PersonDetailViewWithParam = withPersonDetailParam( PersonDetailView, 'caretakerId' );

  return (
    <Switch>
      {!hasCompletedOnboarding && <Redirect to={{ pathname: loginRoute.path, search: generateQueryString({ next: location.pathname, background: dashboardRoute.get() }) }} />}

      <Route exact path={ medicalConsentRoute.path } component={ MedicalConsent }/>
      <Route exact path={ medicalConsentMedicationsRoute.path } component={ MedicalConsentMedicationsForm }/>
      <Route exact path={ medicalConsentAllergiesRoute.path } component={ MedicalConsentAllergiesForm }/>
      <Route exact path={ medicalConsentAddressRoute.path } component={ MedicalConsentAddressForm }/>
      <Route exact path={ medicalConsentDoctorsRoute.path } component={ MedicalConsentDoctorManagement }/>
      <Route path={ medicalConsentCareInfoRoute.path } component={ MedicalConsentCareInformationManagement } />

      <Route path={ medicalConsentDocumentRoute.path }>
        <MedicalConsentDocumentManagement />
        <Route exact path={ medicalConsentCaretakerRoute.path } >

          {/* @todo - find a better way to create a fallback retreat route */}
          <PersonDetailViewWithParam retreatRoute={ location.pathname.split( 'caretaker' )[0] }  />
        </Route>

      </Route>

    </Switch>
  );
}
