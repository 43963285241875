import { ReactElement, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Components
import ActionContainer from '../../../../components/ActionContainer';
import Button from '../../../../components/Button';
import PersonIcon from '../../../People/PersonIcon';
import Translation from '../../../../components/Translation';
import { MedicalConsentDocumentActionSheet } from './MedicalConsentDocumentActionSheet';
import Helper from '../../../../components/Helper';

// Types
import PersonInterface from '../../../../types/PersonInterface';
import Person from '../../../../core-data-service/models/Person';

// Redux
import { useDispatch, useSelector } from '../../../../store';
import { showEditCaretakerModal } from '../../../../store/reducers/modalReducer';
import productsSelector from '../../../../store/selectors/productsSelector';

// Routes
import { checkout } from '../../../../../core/routes';

// Utils
import { separateArrayStringsByComma } from '../../../../../core/utils/stringHelpers';
import Icon from '../../../../components/Icon';
import { DOCUMENTS } from '../../../../core-data-service/models/Document';
import { generateQueryString } from '../../../../utils/urlUtils';
import { isAddressComplete } from '../../../../utils/address';

interface Props {
  person: PersonInterface;
  canOpenDocument: boolean;
  className?: string;
}

export default function CaretakerDocumentSummaryItem({
  person,
  canOpenDocument,
  className = '',
}: Props ): ReactElement{

  const dispatch = useDispatch();
  const _person = new Person( person );

  const { push } = useHistory();
  const { wardId } = useParams<{wardId: string}>();
  const { pro } = useSelector( productsSelector );
  const [ shouldOpenDocumentActionSheet, setShouldOpenDocumentActionSheet ] = useState( false );

  const missingDataString: string[] = [];
  if ( !isAddressComplete( person.address )) {
    missingDataString.push( 'address' );
  }
  if ( !person.phone ) {
    missingDataString.push( 'phone number' );
  }
  if ( !person.email ) {
    missingDataString.push( 'email' );
  }
  const isMissingCaretakerData = !!missingDataString.length;
  const personName = person.name || '';
  const missingDataSeparatedByComma = separateArrayStringsByComma( missingDataString );

  return (
    <div className={ `${className}` }>
      <ActionContainer
        headerClassName={ 'bg-moss rounded mt-6 p-6 relative' }
        key={ person.id }
        header={
          <div className="w-full">
            <div className="flex-1 flex flex-row items-start">
              <div className="flex flex-1 items-center">
                <div className="w-16">
                  <PersonIcon
                    className="aspect-w-1 aspect-h-1"
                    photo_url={ person.photo_url }
                    personName={ person.name }
                    onImageLoadDuration={ 1 }
                    alt="avatar"
                  />
                </div>
                <div className="flex-1 flex flex-col justify-center">
                  <div className="self-stretch flex justify-between">
                    <div>
                      <span className="ml-4 whitespace-nowrap text-black font-bold">
                        { _person.firstName }
                      </span>
                      <span className="ml-4 whitespace-nowrap mt-2 block">
                        {person.relationship}
                      </span>

                    </div>
                  </div>
                </div>
              </div>
              <Button
                small
                theme={ 'clean' }
                onClick = { () => dispatch( showEditCaretakerModal({ caretakerID: person.id })) }
              >
                <Translation translationKey="(button)edit"/>
              </Button>
            </div>

            {/* don't render this until we ask for it to avoid fetching document too soon */}
            {shouldOpenDocumentActionSheet &&
              <MedicalConsentDocumentActionSheet wardId={ wardId } isVisble={ shouldOpenDocumentActionSheet } handleClose={ () => setShouldOpenDocumentActionSheet( false ) } />
            }

          </div>
        }
      />
      {( !isMissingCaretakerData && canOpenDocument ) &&
        <div className="h-10 -mt-2 rounded-b bg-forest hover:bg-grey-400 transition-colors relative">

          <Button
            small
            theme={ 'clean' }
            className="text-white absolute inset-0 w-full"
            onClick={ () => {
              pro.hasPurchased ?
                setShouldOpenDocumentActionSheet( true ):
                push({ pathname: checkout.get(), search: generateQueryString({ document: DOCUMENTS.medical_consent }) });
            } }
          >
            <div className="flex justify-center p-4 w-full">
              <Icon name="documentOutline" className="w-5 h-5 mr-2"/>

              {pro.hasPurchased ?
                <Translation translationKey="medicalconsent.screen.listofcartakers.caretakertile.button.openpdf"/>:
                'Purchase document'
              }
            </div>
          </Button>
        </div>
      }
      {isMissingCaretakerData &&
        <Helper
          className="mt-8"
          body={ <Translation translationKey={ 'medicalconsent.screen.listofcartakers.caretaker.error.missingdata' } dynamicStringValues={ [ personName, missingDataSeparatedByComma ] }/> }
          theme="error"
        />
      }
    </div>
  );
}


