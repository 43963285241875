import { FC, useEffect } from 'react';

// Components / Views
import Flow from '../../components/Flow';
import useAuth from '../../hooks/useAuth';

// Hooks
import useFlow from '../../hooks/useFlow';

// Routes
import PromptForEmail from './PromptForEmail';
import PromptForVerification from './PromptForVerification';

interface AuthenticateProps {
  setParentFlowIndex: ( index: number )=> void;
}

/**
 * Authentication flow
 */
const Authenticate: FC<AuthenticateProps> = ({ setParentFlowIndex }) => {
  const { currentIndex, setCurrentIndex } = useFlow();
  const { shouldUserVerifyEmail } = useAuth();

  useEffect(() => {
    if ( shouldUserVerifyEmail ) {
      loginEmailSent();
    }
  }, []);

  const loginEmailSent = () => {
    setCurrentIndex( 1 );
  };

  const createNewAccount = () => {
    setParentFlowIndex( 1 );
  };

  const onVerificationComplete = () => {
    setParentFlowIndex( -1 );
  };

  return(

    <Flow currentIndex={ currentIndex }>
      <PromptForEmail onLoginEmailSent={ loginEmailSent } onError={ createNewAccount } />
      <PromptForVerification onCancel={ () => setCurrentIndex( 0 ) } onSuccess={ onVerificationComplete }/>
    </Flow>

  );
};

export default Authenticate;
