import { FC, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// Components
import DocumentIntroFlow from '../../../views/Documents/DocumentIntroFlow';
import Translation from '../../../components/Translation';

// Hooks

// Routes
import { login, will } from '../../../../core/routes';

// Redux
import entitlementsSelector from '../../../store/selectors/entitlementsSelector';
import useAuth from '../../../hooks/useAuth';
import { postGoal } from '../../../store/reducers/goalsReducer';
import { useDispatch, useSelector } from '../../../store';
import { setUserAttributes } from '../../../store/reducers/userReducer';

// Types
import { getProductTrackingData } from '../../../utils/analytics';
import { VideoSourceMap } from '../../../components/VideoPlayer';
import { GoalTypes } from '../../../types/GoalInterface';
import useTranslations from '../../../hooks/useTranslations';

// Utils
import { generateQueryString } from '../../../utils/urlUtils';

const WillIntro: FC = () => {
  const { push } = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { getTranslation } = useTranslations();

  // Application state
  const { hasAuthenticated, hasCompletedOnboarding } = useAuth();
  const { will: { upgrades, isEnabled } } = useSelector( entitlementsSelector );

  // @TODO - EPP Analytics
  const premiumWillData = {
    isEnabled,
    upgrades,
  };

  const videoProps: VideoSourceMap = { portrait: 'video.will.intro', landscape: 'video.will.intro.landscape' };

  const onComplete = () => {
    dispatch( setUserAttributes({ 'app.willIntroFinished': true, onSuccess: () => {
      if( hasAuthenticated && hasCompletedOnboarding ) {
        push({ pathname: will.get() });
      } else {
        push({
          pathname: login.get(),
          search: generateQueryString({ next: will.get() }),
          state: { background: location },
        });
      }
    } }));
  };

  // Set will goal
  const existingWillGoal = useSelector( state => state.goals.data
    .find( goal => goal.type === GoalTypes['GET WILL']));

  useEffect(() => {
    if( !existingWillGoal?.id ){
      dispatch( postGoal({ type: GoalTypes['GET WILL'] }));
    }
  }, [ dispatch, existingWillGoal?.id ]);

  return (
    <DocumentIntroFlow
      onComplete={ onComplete }
      video={{
        src: videoProps,
        title: 'Will Basics',
      }}
      introTrackingId="Touch - Begin Will"
      introTrackingData={ getProductTrackingData( premiumWillData ) }
      intro={{
        body: <Translation translationKey={ 'screen.will.intro.description' } />,
        title: <Translation translationKey={ 'screen.will.intro.title' } />,
        minToComplete: '10',
        image: {
          src: getTranslation( 'products.will.intro.headerImage' ),
          alt: 'Basics intro illustration',
        },
      }}
    />
  );
};

export default WillIntro;
