import React, { ReactElement, ReactNode } from 'react';
import { Button } from './Button';

export type CardImage = {
  url: string;
  alt: string;
  rounded?: boolean;
}

export interface CardProps {
  media?: CardImage;
  mediaComponent?: React.ReactNode;
  mediaClasses?: string;
  title: string | ReactNode;
  children: string | ReactNode;
  primaryAction?: ()=> void;
  primaryActionLabel?: string | ReactNode;
  primaryActionId?:string,
  className?: string;
  childrenContainerClassName?: string;
}

export const Card = ({ media, mediaComponent, mediaClasses, title, children, primaryAction, primaryActionLabel = 'Submit',primaryActionId, className, childrenContainerClassName }: CardProps ): ReactElement => {
  return (
    <div
      className={ `
        card
        relative
        flex
        ${className}
        ${primaryAction ? 'transform hover:-translate-y-1 transition-transform': ''}
      ` }>
      {( media || mediaComponent ) &&
      <div className={ `mr-4 flex items-start flex-none ${mediaClasses}` }>
        {mediaComponent ?
          mediaComponent
          :
          media && media.url &&
            <img className={ `${media.rounded ? 'rounded-lg' : ''}` } src={ media.url } alt={ media.alt }/>
        }
      </div>
      }
      <div className="flex flex-col justify-center">
        <div className={ childrenContainerClassName } >
          <h1 className="font-bold mb-2 text-black">{title}</h1>
          {children}
        </div>
        {primaryAction &&
          <div className="mt-4">
            <Button small theme="clean" className="font-bold button--fill" id={primaryActionId} onClick={ primaryAction }>{primaryActionLabel}</Button>
          </div>
        }
      </div>
    </div>
  );
};
