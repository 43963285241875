// Redux
import { useSelector } from '../store';

// Types
import { SectionStates } from '../components/DocumentSectionOverview';
import { Keyable } from '../types/Keyable';

// Hooks
import useHeirs from './useHeirs';
import useTrustees from './useTrustees';
import useUserAttributes from './useUserAttributes';
import { DocumentSectionState, getHeirsState } from './useWillSectionStatus';

export const TrustSections = {
  basics: 'basics',
  trustees: 'trustees',
  heirs: 'heirs',
  review: 'review',
} as const;

export type TrustSection = keyof typeof TrustSections;

export type TrustSectionStates = Record<TrustSection, DocumentSectionState>;

type TrustSectionStatus = {
  sectionStates: Keyable;
  next: TrustSection;
  canCompleteTrust: boolean;
  hasCompletedTrust: boolean;
}

const useTrustSectionStatus = (): TrustSectionStatus => {
  const userAttributes = useSelector( state => state.user.data.attributes );
  const { getTrusteesSectionState } = useTrustees();

  const { documents: {
    trust: {
      hasConfirmedTrustBasics,
      hasViewedPreview: hasViewedTrustPreview,
    },
  } } = useUserAttributes();

  const {
    childrenHeirs,
    fallbackHeirs,
    sumOfChildrenShares,
    sumOfFallbackShares,
    spouse,
  } = useHeirs();

  const isRenderedInWill = false;
  const trustHeirsState = getHeirsState(
    userAttributes,
    childrenHeirs,
    fallbackHeirs,
    sumOfChildrenShares,
    sumOfFallbackShares,
    spouse,
    isRenderedInWill,
  );

  const sectionStates: Keyable = {
    basics: { state: hasConfirmedTrustBasics ? SectionStates.complete : SectionStates.default },
    trustees: { state: getTrusteesSectionState().state },
    heirs: { state: trustHeirsState.state },
    // I think this will basically always be "default", no way you can get in here without this attr being true
    review: { state: hasViewedTrustPreview ? SectionStates.complete : SectionStates.default },
  };
  const next = Object.keys( sectionStates ).find( section => sectionStates[section].state !== SectionStates.complete ) as TrustSection || TrustSections.review;
  const canCompleteTrust = next === TrustSections.review;
  const hasCompletedTrust = sectionStates.review.state === SectionStates.complete;

  return { sectionStates, next, canCompleteTrust, hasCompletedTrust };
};

export default useTrustSectionStatus;
