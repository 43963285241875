import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// initialState
import initialState from '../initial/experiments';

// Utils
import WebClientRequest from '../../core-data-service/WebClientRequest';

interface IPostExperimentImpression {
  variantId: string;
}


export const fetchExperiments = createAsyncThunk(
  'experiments/fetchExperiments',
  async( _, thunkAPI ) => {
    const url = '/v1/experiments/';
    return WebClientRequest
      .get( url )
      .then( response => {
        if( !!response.data.data ){
          thunkAPI.dispatch( saveExperiments({
            data: response.data.data,
          }));
        } else {
          throw new Error(
            '[experiments/fetchExperiments] No experiments in response',
          );
        }
      });
  },
);

// There is no harm in "over-reporting" a viewed experiment, aside from unnecessary calls
export const postExperimentImpression = createAsyncThunk(
  'experiments/postExperimentImpression',
  async({ variantId }: IPostExperimentImpression, thunkAPI ) => {
    const url = '/v1/impressions';
    const data = { variant_id: variantId };
    return WebClientRequest
      .post( url, data )
      .then( _ => thunkAPI.dispatch( logImpression({ variantId })));
  },
);

const experimentsSlice = createSlice({
  name: 'experiments',
  initialState,
  reducers: {

    saveExperiments( experiments, action ){
      const { data } = action.payload;
      Object.assign( experiments.data, data );
    },

    logImpression( experiments, action ){
      const { variantId } = action.payload;
      if( !experiments.meta.impressionsReported.includes( variantId )){
        experiments.meta.impressionsReported.push( variantId );
      } else {
        return experiments;
      }
    },
  },
  extraReducers: builder => {
    builder.addCase( fetchExperiments.pending, ( experiments, action ) => {
      const { arg, ...meta } = action.meta;
      experiments.fetchExperiments.meta = meta;
    });
    builder.addCase( fetchExperiments.fulfilled, ( experiments, action ) => {
      const { arg, ...meta } = action.meta;
      experiments.fetchExperiments.meta = meta;
    });
    builder.addCase( fetchExperiments.rejected, ( experiments, action ) => {
      const { arg, ...meta } = action.meta;
      experiments.fetchExperiments.meta = meta;
    });
    builder.addCase( postExperimentImpression.pending, ( experiments, action ) => {
      const { arg, ...meta } = action.meta;
      experiments.postExperimentImpression.meta = meta;
    });
    builder.addCase( postExperimentImpression.fulfilled, ( experiments, action ) => {
      const { arg, ...meta } = action.meta;
      experiments.postExperimentImpression.meta = meta;
    });
    builder.addCase( postExperimentImpression.rejected, ( experiments, action ) => {
      const { arg, ...meta } = action.meta;
      experiments.postExperimentImpression.meta = meta;
    });
  },
});




export const { saveExperiments, logImpression } = experimentsSlice.actions;

export default experimentsSlice.reducer;
