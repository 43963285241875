import { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

// Types
import SanitizedFormData from '../../../core/types/SanitizedFormData';

// Components
import Button from '../../components/Button';
import Form from '../../components/Form';
import Translation from '../../components/Translation';

// Redux
import { patchPerson } from '../../store/reducers/peopleReducer';
import { useSelector, useDispatch } from '../../store';
import userPersonSelector from '../../store/selectors/userPersonSelector';

// Utils
import analytics from '../../utils/analytics';
import SelectField from '../../components/SelectField';
import Address from '../../core-data-service/models/Address';
import { fieldRequired } from '../../formValidators';


interface PromptForStateProps {
  className?: string;
  onSuccess?: ()=> void;
}

interface PromptForStateFormData extends SanitizedFormData {
  state: string;
}

const PromptForState: FC<PromptForStateProps> = ({
  className,
  onSuccess,
}) => {

  // Application state
  const dispatch = useDispatch();
  const userPerson = useSelector( userPersonSelector );
  const isPending = useSelector( state => state.people?.patchPerson?.meta?.requestStatus === 'pending' );
  const existingAddress = userPerson?.address;

  // Form hooks
  const { register, getValues, setValue, handleSubmit, formState } = useForm<PromptForStateFormData>({
    reValidateMode: 'onChange',
    defaultValues: {
      ...existingAddress,
    },
  });

  useEffect(() => {
    analytics.track( 'View - Registration State' );
  }, []);

  const { errors } = formState;
  const useFormProps = { register, getValues, setValue, errors };

  // onSubmit handler
  const onSubmit: SubmitHandler<SanitizedFormData> = data => {
    const address = {
      ...existingAddress,
      state: data.state,
    };

    userPerson && dispatch( patchPerson({ id: userPerson.id, address, onSuccess }));
  };

  return(
    <Form
      className={ className }
      handleSubmit={ handleSubmit }
      onSubmit={ onSubmit }
    >
      <h1 className="text-3xl mb-6 text-black font-headline"><Translation translationKey="account.signup.state.title"/></h1>
      <SelectField { ...useFormProps }
        label={ <Translation translationKey="address.state.label"/> }
        name="state"
        options={ Address.US_STATES }
        uniqueId="state"
        required={ fieldRequired( 'Your state is required' ) }
      />
      <p className="mb-6 text-black-50">
        <Translation translationKey={ 'account.signup.location.desc' } />
      </p>
      <div className="flex justify-end">
        <Button
          isPending={ isPending }
          type="submit"
          id="onboarding-continue-button"
        ><Translation translationKey="(button)continue"/></Button>
      </div>
    </Form>

  );
};

export default PromptForState;
