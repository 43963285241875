import { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

// Types
import SanitizedFormData from '../../../core/types/SanitizedFormData';

// Utils
import analytics from '../../utils/analytics';
import { fieldRequired } from '../../formValidators';
import useTranslations from '../../hooks/useTranslations';

// Components
import Button from '../../components/Button';
import Form from '../../components/Form';
import TextField from '../../components/TextField';
import Translation from '../../components/Translation';
import { FORM_PERSON_INPUTS } from '../People/PersonDetailForm';
import inputMask from '../../utils/inputMask';
import { formatDate, isDateInPast } from '../../../core/utils/datetimeUtils';
import { birthdateMinLength } from '../../formValidators';
import { useSelector } from '../../store';
import onboardingSelector from '../../store/selectors/onboardingSelector';

interface PromptForBirthdayProps {
  className?: string;
  onFormComplete?: ( data: SanitizedFormData )=> void;
}

interface PromptForBirthdayFormData extends SanitizedFormData {
  birthday: string;
}

const PromptForBirthday: FC<PromptForBirthdayProps> = ({
  className,
  onFormComplete,
}) => {

  const { getTranslation } = useTranslations();
  const onboardingIdentity = useSelector( onboardingSelector );

  // Form hooks
  const { register, setValue, getValues, handleSubmit, formState } = useForm<PromptForBirthdayFormData>({
    reValidateMode: 'onChange',
    defaultValues: {
      dob: formatDate( onboardingIdentity.birthDate ),
    },
  });

  const { errors } = formState;
  const useFormProps = { register, setValue, getValues, errors };

  // onSubmit handler
  const onSubmit: SubmitHandler<SanitizedFormData> = data => {
    const date = new Date( data.dob ?? '' ).toISOString();
    onFormComplete && onFormComplete({ birthDate: date });
  };

  useEffect(() => {
    analytics.track( 'View - Registration DOB' );
  }, []);

  return(
    <Form
      className={ className }
      handleSubmit={ handleSubmit }
      onSubmit={ onSubmit }
    >
      <h1 className="text-3xl mb-6 text-black font-headline"><Translation translationKey={ 'screen.onboarding.dob.title' }/></h1>
      <TextField { ...useFormProps }
        label={ <Translation translationKey={ 'addperson.fields.dob.label' }/> }
        minLength={ birthdateMinLength( getTranslation( 'addperson.error.dob.type' )) }
        name={ FORM_PERSON_INPUTS.DOB }
        normalize={ inputMask.date }
        placeholder="MM/DD/YYYY"
        required={ fieldRequired( getTranslation( 'addperson.error.dob.required' )) }
        type="tel"
        validate={ dobString => {
          if( !dobString || isDateInPast( new Date( dobString ))) {
            return true;
          } else {
            return getTranslation( 'addperson.error.dob.value' );
          }
        } }
      />
      <p className="mb-6 text-black-50">
        <Translation translationKey={ 'screen.onboarding.dob.subtitle' }/>
      </p>
      <div className="flex justify-end">
        <Button
          id="onboarding-continue-button"
          type="submit"
        >Continue</Button>
      </div>
    </Form>
  );
};

export default PromptForBirthday;
