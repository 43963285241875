import { ReactElement } from 'react';

// Components
import Button, { ButtonProps } from './Button';
import Icon, { Icons } from './Icon';
import Translation from './Translation';

// Types
import { TranslationKeys } from '../store/initial/translations';

interface IAddButton {
  className?: ButtonProps['className'];
  iconName?: Icons;
  isPending?: ButtonProps['isPending'];
  labelTranslationKey?: TranslationKeys;
  onClick: ButtonProps['onClick'];
  buttonId?:string;
}

export default function AddButton({ className, labelTranslationKey='(button)add.generic', onClick, isPending, iconName,buttonId }: IAddButton ): ReactElement{

  return (
    <Button
      isPending={ isPending }
      id={buttonId}
      theme="flat"
      className={ `w-full h-10 ${className}` }
      onClick={ onClick }
    >
      <div className="flex items-center justify-center">
        {iconName && <Icon name={ iconName } className="mr-2 w-5 h-5" />}
        {labelTranslationKey && <span><Translation translationKey={ labelTranslationKey }/></span>}
      </div>
    </Button>
  );
}
