import { FC, useEffect, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

// Types
import SanitizedFormData from '../../../core/types/SanitizedFormData';

//import userSelector from '../../store/selectors/userSelector';
import analytics from '../../utils/analytics';
import useTranslations from '../../hooks/useTranslations';

// Components
import Button from '../../components/Button';
import Form from '../../components/Form';
import Translation from '../../components/Translation';
import SelectField from '../../components/SelectField';
import { useSelector } from '../../store';
import onboardingSelector from '../../store/selectors/onboardingSelector';


interface PromptForGenderProps {
  className?: string;
  onFormComplete?: ( data: SanitizedFormData )=> void;
}

interface PromptForGenderFormData extends SanitizedFormData {
  gender: string;
}

const PromptForGender: FC<PromptForGenderProps> = ({
  className,
  onFormComplete,
}) => {

  const { getTranslation } = useTranslations();
  const onboardingIdentity = useSelector( onboardingSelector );

  // Form hooks
  const { register, setValue, getValues, handleSubmit, formState } = useForm<PromptForGenderFormData>({
    reValidateMode: 'onChange',
    defaultValues: {
      gender: onboardingIdentity.gender,
    },
  });

  const { errors } = formState;

  const useFormProps = {
    register,
    setValue,
    getValues,
    errors,
    required: {
      value: true,
      message: getTranslation( 'address.error.gender' ),
    },
  };

  // onSubmit handler
  const onSubmit: SubmitHandler<SanitizedFormData> = data => {
    onFormComplete && onFormComplete( data );
  };

  useEffect(() => {
    analytics.track( 'View - Registration Gender' );
  }, []);

  return(
    <Form
      className={ className }
      handleSubmit={ handleSubmit }
      onSubmit={ onSubmit }
    >
      <h1 className="text-3xl mb-6 text-black font-headline"><Translation translationKey={ 'screen.onboarding.gender.title' }/></h1>
      <SelectField
        { ...useFormProps }
        label={ getTranslation( 'profile.gender.title' ) }
        name="gender"
        uniqueId="gender"
        showLabel={ false }
        options={
          [
            { label: getTranslation( 'account.signup.name.fields.label.male' ), value: 'Male' },
            { label: getTranslation( 'account.signup.name.fields.label.female' ), value: 'Female' },
          ]
        }
      />
      <p className="mb-6 text-black-50">
        <Translation translationKey={ 'screen.onboarding.gender.subtitle' }/>
      </p>
      <div className="flex justify-end">
        <Button
          id="onboarding-continue-button"
          type="submit"
        >Continue</Button>
      </div>
    </Form>
  );
};

export default PromptForGender;
