import { ReactElement } from 'react';

// Components
import Button from './Button';
import { Card } from './Card';
import Icon from './Icon';

type Props = {
  className?: string;
  primaryAction: ()=> void;
  primaryActionLabel: string;
  title: string;
  description: string;
}

export default function ProductUpsellHelper({ className = '', primaryAction, title, description, primaryActionLabel }: Props ): ReactElement{
  return (
    <Card
      className={ `bg-salamander--light p-6 rounded relative ${className}` }
      title={ title }
      mediaComponent={ <Icon className="text-salamander" name="sparkle" /> }
      childrenContainerClassName="ml-2"
    >
      <>
        <p className="mb-4">{description}</p>
        <Button
          id='buy-button'
          className="button--fill"
          onClick={ primaryAction }
          small
          theme="quarternary"
        >
          {/* @TODO - Translation */}
          {primaryActionLabel}
        </Button>
      </>
    </Card>
  );
}
