import { ReactElement } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

// Routes
import { login as loginRoute, dashboard as dashboardRoute, trust_basics, trust, trustees, trust_heirs, pourover_will, trust_review, checkout } from '../../../../core/routes';
import useUserAttributes from '../../../hooks/useUserAttributes';

// Redux
import { useSelector } from '../../../store';
import entitlementsSelector from '../../../store/selectors/entitlementsSelector';
import productsSelector from '../../../store/selectors/productsSelector';

// Utils
import { generateQueryString } from '../../../utils/urlUtils';
import useAuth from '../../../hooks/useAuth';
import { DOCUMENTS } from '../../../core-data-service/models/Document';

// Views
import DocumentBasics from '../../../views/Documents/DocumentBasics';
import Trust from '../../../views/Documents/Trust';
import PourOverWill from '../../../views/Documents/Trust/PourOverWill';
import TrustHeirs from '../../../views/Heirs/TrustHeirs';
import Trustees from '../../../views/Trustees';
import TrustReviewRouter from './TrustReviewRouter';

// Helpers
import { UNSUPPORTED_DOCUMENT_FALLBACK_ROUTE } from '../../../../core/routerConstants';

export default function TrustRouter(): ReactElement{

  const {
    documents: { trust: { hasViewedPreview } },
  } = useUserAttributes();

  const location = useLocation();

  const { pro } = useSelector( productsSelector );
  const { availableDocuments } = useSelector( entitlementsSelector );
  const isDocumentSupported = availableDocuments.includes( DOCUMENTS.trust );
  const { hasCompletedOnboarding } = useAuth();

  return (
    <Switch>
      {/* @TODO: Refactor for all the documents */}
      {!hasCompletedOnboarding && <Redirect to={{ pathname: loginRoute.path, search: generateQueryString({ next:location.pathname, background:dashboardRoute.get() }) }} />}
      {!isDocumentSupported && <Redirect to={ UNSUPPORTED_DOCUMENT_FALLBACK_ROUTE }/> }

      <Route path={ trust.path } >
        <>
          <Route exact path={ trust.path } >
            {hasViewedPreview && pro.hasPurchased ?
              <Redirect to={ trust_review.path } /> :
              <Trust />
            }
          </Route>

          <Route exact path={ trust_basics.path } component={ DocumentBasics } />
          <Route exact path={ trustees.path } component={ Trustees } />
          <Route exact path={ trust_heirs.path } component={ TrustHeirs }/>

          <Route path={ trust_review.path } >
            { pro.hasPurchased ?
              <TrustReviewRouter /> :
              <Redirect to={{
                pathname: checkout.path,
                search: generateQueryString({ document: DOCUMENTS.trust }),
              }} />
            }
          </Route>

          <Route exact path={ pourover_will.path } component={ PourOverWill } />
        </>

      </Route>
    </Switch>
  );
}
