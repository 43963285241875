import { ReactElement, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

// Components
import Form from '../../../components/Form';
import Button from '../../../components/Button';
import SegmentView from '../../../components/SegmentView';
import Translation from '../../../components/Translation';
import { Checkbox } from '../../../components/Checkbox';

// Hooks
import useBackstack from '../../../hooks/useBackstack';
import usePowers from '../../../hooks/usePowers';
import { useDispatch, useSelector } from '../../../store';
import useTranslations from '../../../hooks/useTranslations';
import { GENERAL_AUTHORITY, SPECIAL_INSTRUCTIONS } from '../../../hooks/usePoaSectionStatus';

// Routes
import { poa as poaRoute } from '../../../../core/routes';

// Redux
import { patchPower } from '../../../store/reducers/powersReducer';
import { makeSnack } from '../../../store/reducers/snackbarReducer';

// Types
import SanitizedFormData from '../../../../core/types/SanitizedFormData';
import { FormAnswersInterface } from '../../../types/FormInterface';
import { isPendingPowersSave } from '../../../store/selectors/powersSelector';

interface Props {
  onSuccess: ()=> void;
}
export default function PowerGrantQuestions({ onSuccess }: Props ): ReactElement{
  const { goBack } = useBackstack( poaRoute.get());
  const { powersData, powersForm, powers } = usePowers();
  const dispatch = useDispatch();

  const defaultValues = useMemo(()=>{
    return powers;
  },[ powers ]);

  const [ hasGrantedGeneralAuthority, setHasGrantedGeneralAuthority ] = useState( powers[GENERAL_AUTHORITY]);

  const { register, setValue, getValues, errors, handleSubmit, watch } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    shouldUnregister: false,
    defaultValues,
  });

  const useFormProps = {
    register,
    setValue,
    getValues,
    errors,
  };

  const { instructions, general_authority: generalAuthorityChecked, ...powersChecked } =watch();
  const shouldUncheckGeneralAuthority = Object.values( powersChecked ).some( value => !value );
  // If a user deselects a non-general authority question while select all is checked, make sure to deselect general authority aka select all
  useEffect(() => {
    if ( shouldUncheckGeneralAuthority ) {
      setHasGrantedGeneralAuthority( false );
      setValue( 'general_authority', false );
    }
  }, [ shouldUncheckGeneralAuthority, setValue ]);

  // If general authority is checked or unchecked, make sure each box receives the same value
  useEffect(() => {
    if( generalAuthorityChecked !== hasGrantedGeneralAuthority ) {
      powersForm.forEach( question =>{
        if( question.id === GENERAL_AUTHORITY || question.id === SPECIAL_INSTRUCTIONS ){
          return;
        }
        setValue( question.id, generalAuthorityChecked );
      });

      setHasGrantedGeneralAuthority( generalAuthorityChecked );
    }
  }, [ generalAuthorityChecked, hasGrantedGeneralAuthority, watch, setValue, powersData, powersForm ]);

  const isRequestPending = useSelector( isPendingPowersSave );

  const  questions  = powersForm.map( formQuestion => {
    if([ GENERAL_AUTHORITY, SPECIAL_INSTRUCTIONS ].includes( formQuestion.id )){
      return null;
    }
    //TODO: remove this label and use label in form when they are ready.
    let label = formQuestion.text.question?.replace( 'Grant the power over','' ) || '';
    label = label.replace( 'Grant the power','' ).trim();
    label = label[0].toUpperCase() + label.slice( 1 );
    return (
      <Checkbox
        { ...useFormProps }
        className={ 'pr-4 py-2 border-b border-white last:pb-4 last:border-b-0' }
        key={ formQuestion.id }
        id={ formQuestion.id }
        name={ formQuestion.id }
        value={ 'true' }
        label={ label }/>
    );
  });

  const generalAuthorityQuestion = powersForm.filter( question => question.id === GENERAL_AUTHORITY )
    .map( ga =>{
      return (
        <Checkbox
          { ...useFormProps }
          className={ 'border-b-2 py-2 border-forest' }
          key={ ga.id }
          value={ 'true' }
          id={ ga.id }
          name={ ga.id }
          label={ 'Select All' }
        />
      );
    });

  const { getTranslation } = useTranslations();

  const onSubmit: SubmitHandler<SanitizedFormData> = data =>{
    const updatedPowers: FormAnswersInterface={};
    const { instructions, ...powersWithoutInstructions } = data;

    // If no powers have been granted show error snack bar before letting user continue
    const hasNotGrantedPowers = !Object.values( powersWithoutInstructions ).some( power => power );
    if ( hasNotGrantedPowers ) {
      dispatch( makeSnack({ theme: 'error', message: getTranslation( 'poa.chapter.error.no.powers' ) }));
    } else {
      if( !!data ) {
        Object.keys( data ).forEach( question => {
          if ( data[question] === undefined ) {
            updatedPowers[question] = false;
          } else if ( data[question] === 'true' ) {
            updatedPowers[question] = true;
          } else {
          //We need this if there is hidden question.
            updatedPowers[question] = defaultValues[question];
          }
        });
        updatedPowers[SPECIAL_INSTRUCTIONS] = defaultValues[SPECIAL_INSTRUCTIONS];
      }
      const postPowers = {
        ...powersData[0],
        powers: updatedPowers,
      };
      dispatch( patchPower({
        onSuccess,
        ...postPowers }));
    }
  };

  return  (
    <Form handleSubmit={ handleSubmit } onSubmit={ onSubmit }>
      <SegmentView onRetreat={ goBack }
        title={ <Translation translationKey={ 'tile.poa.powers.subtitle.unstarted' }/> }
        contentContainerPadding="!px-0 !pt-0"
        contentBackgroundColorClass="transparent"
        hidePrimaryAction >
        <div className={ 'bg-moss rounded-lg' }>
          {!!generalAuthorityQuestion && generalAuthorityQuestion}
          {questions}
        </div>
        <div className={ 'float-right pt-8' } >
          <Button id='poa-powers-done-button'theme={ 'primary' } type={ 'submit' } isPending={ isRequestPending }>
            <Translation translationKey={ '(button)done' }/>
          </Button>
        </div>
      </SegmentView>
    </Form>
  );
}
