import AsyncThunkMetaInterface from '../../types/AsyncThunkMetaInterface';

const initialState = {
  data: {
    email: '',
    nameFirst: '',
    nameLast: '',
    addressData: {
      postalCode: '',
      type: 'primary',
    },
    birthDate: '',
    gender: '',
    recaptcha: {
      token: '',
      type: 'checkbox',
      userAction: 'SIGNUP',
      source: 'eppweb',
    },
  },

  sendLoginEmail: {
    meta: {} as AsyncThunkMetaInterface,
  },

  createAccount: {
    meta: {} as AsyncThunkMetaInterface,
  },

  login: {
    meta: {} as AsyncThunkMetaInterface,
  },
};

export default initialState;
