import axios, { AxiosError, AxiosResponse } from 'axios';
import combineURLs from 'axios/lib/helpers/combineURLs';
import jwt from 'jwt-decode';

// Utils
import log from '../utils/log';

// Redux
import { storeAccessToken } from '../store/reducers/authReducer';
import store from '../store';

import appConfig from '../../core/appConfig';


export interface WebClientRequestHeaders {
  Authorization?: string;
  'Content-Type'?: 'application/json' | 'multipart/form-data';
}

const DEFAULT_ERROR_MESSAGE = 'Network Error';
const AUTHENTICATION_ERROR_MESSAGE = 'You\'re currently logged out, please log back in';
const REQUEST_LATENCY = 30;
const MILLISECONDS = 1000;
const BASE_URL = appConfig.api.baseurl;

/**
 * Make a request to the CoreDataService from the web client
 */
class WebClientRequest {

  protected static TIMERS = 0;
  protected static TOKEN_EXPIRED = true;

  /**
   * Make a GET request to `CoreDataService`
   */
  static async get( path: string ): Promise<AxiosResponse>{
    const url = this.getUrl( path );
    log.info( `GET ${url}` );
    return axios
      .get( url, { headers: this.headers() })
      .catch( err => this.handleError( err )
        .then( async()=> axios
          .get( url, { headers: this.headers() })
          .catch(( err => this.handleError( err, true )
            .then()
          )),
        ),
      );
  }

  /**
   * Make a POST request to `CoreDataService`
   */
  static async post( path: string, data = {}, headers?: WebClientRequestHeaders ): Promise<AxiosResponse>{
    const url = this.getUrl( path );
    log.info( `POST ${url}` );
    return axios
      .post( url, data, { headers: { ...headers, ...( this.headers()) } })
      .catch( err => this.handleError( err )
        .then( async()=> axios
          .post( url, data, { headers: { ...headers, ...( this.headers()) } })
          .catch(( err => this.handleError( err, true )
            .then()
          )),
        ),
      );
  }

  /**
   * Make a patch request to `CoreDataService`
   */
  static async patch( path: string, data = {}): Promise<AxiosResponse>{
    const url = this.getUrl( path );
    log.info( `PATCH ${url}` );
    return axios
      .patch( url, data, { headers: this.headers() })
      .catch( async( err: AxiosError ) =>
        this.handleError( err )
          .then( async()=> axios
            .patch( url, data, { headers: this.headers() })
            .catch((( err: AxiosError ) => this.handleError( err, true )
              .then()
            )),
          ),
      );
  }

  /**
   * Make a delete request to `CoreDataService`
   */
  static async delete( path: string ): Promise<AxiosResponse>{
    const url = this.getUrl( path );
    log.info( `DELETE ${url}` );
    return axios
      .delete( url, { headers: this.headers() })
      .catch( err => this.handleError( err )
        .then( async()=> axios
          .delete( url, { headers: this.headers() })
          .catch(( err => this.handleError( err, true )
            .then()
          )),
        ),
      );
  }

  public static get token(): string|undefined{
    return store.getState().auth.access_token;
  }

  public static set token( token: string|undefined ){
    store.dispatch( storeAccessToken( token ));
    this.TOKEN_EXPIRED = false;
    // @ts-ignore
    const { iat, exp, grant } = jwt( token );
    const timeout = exp - iat - REQUEST_LATENCY;
    const shouldInvalidate = (
      grant === 'password' &&
      timeout > 0 &&
      ++this.TIMERS === 1
    );
    if ( shouldInvalidate ) {
      setTimeout(() => {
        this.TIMERS = 0;
        this.TOKEN_EXPIRED = true;
      },
      timeout * MILLISECONDS );
    }
  }

  /**
   * returns request authorization header
   * @returns Promise<WebClientRequestHeaders>
   */
  protected static headers(): WebClientRequestHeaders{
    const headers: WebClientRequestHeaders = {};
    const accessToken = this.token;
    if( !!accessToken ){
      headers.Authorization = `Bearer ${accessToken}`;
    }
    return headers;
  }

  /**
   * Handle axios caught error responses
   * @param {Error} error handle it
   */
  protected static async handleError( error: AxiosError, isRetry = false ): Promise<void>{
    const status = error?.response?.status ?? error?.response?.data?.error?.code ?? null;
    const statusCode: number = status ? ~~status : 500;
    const reauthenticateStatuses = [ 401, 403 ];

    if( reauthenticateStatuses.includes( statusCode )){
      if( isRetry ){

        //TODO: move this disatch to when we log out the user - they should be in sync
        //store.dispatch( hasAuthenticated( false ));
        return Promise.reject( AUTHENTICATION_ERROR_MESSAGE );
      }

      //TODO: This should just logout the user since we no longer have refresh tokens
      // Or bring them to the onboarding flow...

      // await this.refreshAccessToken();
      return Promise.resolve();
    }

    return  Promise.reject( error?.response?.data?.error?.detail ?? DEFAULT_ERROR_MESSAGE );
  }

  /**
   * composes request URL
   * @param path
   * @returns string
   */
  public static getUrl( path: string ): string{
    return combineURLs( BASE_URL, path );
  }

}


export default WebClientRequest;
